import { PlacebuzzGuard } from "./placebuzz.guard";
import { Routes } from "@angular/router";
import { FourOFourContainer } from "./components/four-o-four/four-o-four.container";
import { HomeContainerComponent } from "./pages/home/home.container.component";
import propertyDetailsRoutes from "./pages/property-details/property-details-routes";
import searchRoutes from "./pages/property-search/property-search-routes";


const loginComponent = () => import('src/app/pages/login/login.container.component').then(m => m.LoginContainerComponent);
const contentComponent = () => import('src/app/pages/cms-content/content.container.component').then(m => m.ContentContainerComponent);
const unsubscribeComponent = () => import('src/app/pages/unsubscribe/unsubscribe.container.component').then(m => m.UnsubscribeContainerComponent);

export const routes: Routes = [

    { path: 'searches', loadComponent: () => import('./pages/saved-searches/saved-searches.container').then(m => m.SavedSearchesContainer), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true, canOnlyAccessWithValidToken: true } },
    { path: 'search', loadComponent: () => import('./pages/search/search.container.component').then(m => m.SearchContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'favourites', loadComponent: () => import('./pages/favourites/favourites.container.component').then(m => m.FavouritesContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false,canOnlyAccessWithValidToken: true  } },
    { path: 'enquiries', loadComponent: () => import('./pages/enquiries/enquiries.container.component').then(m => m.EnquiriesContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true,canOnlyAccessWithValidToken: true  }},
    
    { path: 'contact-agent/:propertyId', redirectTo: '/property-for-rent/details/:propertyId/contact-agent', pathMatch:'full' },
    
    //{ path: "property-for-sale/details/:propertyId", loadChildren: () => import('./pages/property-details/property-details-routes').then(m => m.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true } },
    {
        path: 'property-for-sale/details/:propertyId',
        children: propertyDetailsRoutes
      },
    //{ path: 'property-for-sale/:locality/:area', loadChildren: () => import('./pages/property-search/property-search-routes').then(mod => mod.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true }},
    {
        path: 'property-for-sale/:locality/:area',
        children: searchRoutes
    },
    // { path: 'property-for-sale/search', loadChildren: () => import('./pages/property-search/property-search-routes').then(mod => mod.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true }},
    {
        path: 'property-for-sale/search',
        children: searchRoutes
    },
    { path: 'property-for-sale/:locality', loadComponent: () => import('./pages/locality/locality.container.component').then(m => m.LocalityContainerComponent), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true } },
    { path: 'property-for-sale', loadComponent: () => import('./pages/administrative-area/administrative.area.container.component/administrative.area.container.component').then(m => m.AdministrativeAreaContainerComponent), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true, pageType: 'property-for-sale' } },
    
    // { path: "property-for-rent/details/:propertyId", loadChildren: () => import('./pages/property-details/property-details-routes').then(m => m.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true } },
    {
        path: 'property-for-rent/details/:propertyId',
        children: propertyDetailsRoutes
    },
    // { path: 'property-for-rent/:locality/:area', loadChildren: () => import('./pages/property-search/property-search-routes').then(mod => mod.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true }},
    {
        path: 'property-for-rent/:locality/:area',
        children: searchRoutes
    },
    // { path: 'property-for-rent/search', loadChildren: () => import('./pages/property-search/property-search-routes').then(mod => mod.default), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true }},
    {
        path: 'property-for-rent/search',
        children: searchRoutes
    },
    { path: 'property-for-rent/:locality', loadComponent: () => import('./pages/locality/locality.container.component').then(m => m.LocalityContainerComponent), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true } },
    { path: 'property-for-rent', loadComponent: () => import('./pages/administrative-area/administrative.area.container.component/administrative.area.container.component').then(m => m.AdministrativeAreaContainerComponent), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true,allowClientCredentials: true, pageType: 'property-for-rent' } },
    
    // {path: '', loadComponent: () => import('src/app/pages/home/home.container.component').then(mod => mod.HomeContainerComponent), canActivate: [PlacebuzzGuard], data: { allowAnonymous: true, allowClientCredentials: true }},
    {
        path: '',
        component: HomeContainerComponent,
        canActivate: [PlacebuzzGuard],
        data: { allowAnonymous: true, allowClientCredentials: true }
      },


    { path: 'account', loadComponent: () => import('src/app/pages/account/my.account.container.component/my.account.container.component').then(m => m.MyAccountContainer), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, canOnlyAccessWithValidToken: true } },
    
    { path: 'logon', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true, jumpToStep: 1 } },
    { path: 'magic-link-expired', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true, jumpToStep: 0 } },
    { path: 'not-me', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, jumpToStep: -1 } },
    { path: 'reset-password', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true, jumpToStep: 8 } },
    { path: 'sign-up', loadComponent: loginComponent, data: { allowClientCredentials: true, jumpToStep: 4 } },
    { path: 'confirm', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, jumpToStep: 6, canOnlyAccessWithValidToken: true } },
    
    { path: 'account/confirm', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, jumpToStep: 6, canOnlyAccessWithValidToken: true } },
    
    
    { path: 'account/unsubscribeemailsearchupdates', loadComponent: unsubscribeComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, doNotLoginWithToken: true } },
    { path: 'account/unsubscribe-email-search-updates', loadComponent: unsubscribeComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, doNotLoginWithToken: true}},
    { path: 'account/unsubscribe-email-newsletters', loadComponent: unsubscribeComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, doNotLoginWithToken: true} },
    { path: 'account/unsubscribe-email-partner-offers', loadComponent: unsubscribeComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, doNotLoginWithToken: true} },

    { path: 'forgot-password', loadComponent: loginComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false, jumpToStep: 5, canOnlyAccessWithValidToken: true } },

    { path: 'partner-area', loadChildren: () => import('./pages/partner-area/partner-area.routes').then(m => m.default), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false } },
    { path: 'partner-area/account-settings', redirectTo: '/members/account-settings', pathMatch:'full' },
    { path: 'partner-area/manage-listings', redirectTo: '/members/manage-listings', pathMatch:'full' },
    { path: 'partner-area/agent-activity-report', redirectTo: '/members/performance', pathMatch:'full' },
    { path: 'partner-area/agent-activity-leads-report', redirectTo: '/members/leads', pathMatch:'full' },

    { path: 'idara', loadChildren: () => import('./pages/idara/idara.routes').then(m => m.default), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: false } },

    { path: 'area-guides', loadChildren: () => import('./pages/area-guides/area-guides.routes').then(m => m.default), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },

    { path: 'contact-us', loadComponent: () => import('./pages/static-pages/contact-us/contact.us.static.container.component').then(m => m.ContactUsStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'join-us', loadComponent: () => import('./pages/static-pages/join-us/join.us.static.container.component').then(m => m.JoinUsStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'cookies', loadComponent: () => import('./pages/static-pages/cookies/cookies.static.container.component').then(m => m.CookiesStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'display-advertising', loadComponent: () => import('./pages/static-pages/display-advertising/display.advertising.static.container.component').then(m => m.DisplayAdvertisingStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'faq', loadComponent: () => import('./pages/static-pages/faq/faq.static.container.component').then(m => m.FaqStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'feedback', loadComponent: () => import('./pages/static-pages/feedback/feedback.static.container.component').then(m => m.FeedbackStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'how-it-works', loadComponent: () => import('./pages/static-pages/how-it-works/how.it.works.static.container.component').then(m => m.HowItWorksStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'advertise-your-property', loadComponent: () => import('./pages/static-pages/list-properties/list.properties.static.container.component').then(m => m.ListPropertiesStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'share/getapp', loadComponent: () => import('./pages/static-pages/mobile-app/mobile.app.static.container.component').then(m => m.MobileAppStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'press', loadComponent: () => import('./pages/static-pages/press/press.static.container.component').then(m => m.PressStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'privacy', loadComponent: () => import('./pages/static-pages/privacy-policy/privacy.policy.static.container.component').then(m => m.PrivacyPolicyStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'terms', loadComponent: () => import('./pages/static-pages/terms-of-use/terms.of.use.static.container.component').then(m => m.TermsOfUseStaticContainerComponent), canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    // { path: 'dummy-content-route', loadChildren: () => import('src/app/modules/container.modules/content.container.module/content.container.module').then(m => m.ContentContainerModule) },

    { path: 'members', loadChildren: () => import('./pages/members/members.routes').then(m => m.default), data: { allowClientCredentials: false, canOnlyAccessWithValidToken: true, hideNavBar:true, hideFooter:true}},

    //CMS
    { path: 'for-agents', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'for-agents/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'for-agents/:subdir/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    
    { path: 'property-advice', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'property-advice/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'property-advice/:subdir/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    
    { path: 'developers', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    { path: 'developers/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true }},
    { path: 'developers/:subdir/:article', loadComponent: contentComponent, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },
    
    { path: 'blog', loadComponent: () => import('src/app/pages/cms-content/content.container.component').then(m => m.ContentContainerComponent),canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true }},
    { path: 'blog/:article', loadComponent: contentComponent,canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true }},
    { path: 'blog/:subdir/:article', loadComponent: contentComponent,canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } },

    { path: '**', component: FourOFourContainer, canActivate: [PlacebuzzGuard], data: { allowClientCredentials: true } }
];

