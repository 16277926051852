import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { PbIconComponent } from 'src/app/controls/pb-icon/pb-icon.component';

@Component({
    selector: 'related-searches',
    templateUrl: './related-searches.component.html',
    styleUrls: ['./related-searches.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgFor, PbIconComponent]
})

export class RelatedSearchesComponent implements OnInit {

  @Input() datasource: any[];
  @Input() title: string;
  @Input() trackingkey:string;
  @Input() compact: boolean = false;
  @Output() relatedSearchClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }

  onRelatedSearchClick(event: Event, item: any) {
    event.preventDefault();
    event.stopPropagation();
    this.relatedSearchClick.emit(item);
  }

}
