import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, Virtual, Lazy, Zoom } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { LinkbuttonComponent } from '../link-button/linkbutton.component';
import { RouterModule } from '@angular/router';
import { SwiperComponentBase } from '../../components/component.base.classes/swiper.component.base';

SwiperCore.use([Navigation, Pagination, Scrollbar, Virtual, Lazy, Zoom]);

@Component({
    selector: 'pb-carousel',
    templateUrl: './pb-carousel.component.html',
    imports: [CommonModule, RouterModule, LinkbuttonComponent, SwiperModule],
    styleUrls: ['./pb-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbCarouselComponent extends SwiperComponentBase implements AfterViewInit {
  @ViewChild('swiper') swiper?: SwiperComponent;

  constructor(private cdr: ChangeDetectorRef){
    super();
  }
  
  public activeSlide = 1;
  totalSlides = 0;
  _images: string[] = [];
  _sliderImages: string[] = [];

  counterText:string;
  
  
  @Input() hasCounter: boolean = false;
  @Input() mode:string = "image";
  @Input() fullScreenUrl: string;
  
  @Output() slideChange = new EventEmitter<number>();
  @Output() fullScreenClicked = new EventEmitter<string>();
  

  @Input() set images(value: string[]) {
    this._images = value;
    this.totalSlides = value.length;
    this.counterText = `${this.activeSlide} / ${this.totalSlides}`;
  }


  ngAfterViewInit() {
    // Ensure the Swiper instance is available
    if (this.swiper && this.swiper.swiperRef) {
      // Set up the event listener for slideChange
      this.swiper.swiperRef.on('slideChange', () => {
        this.onSlideChange();
      });
    }
  }

  get images(): string[] {
    return this._images;
  }

  onFullScreenClicked(){
    this.fullScreenClicked.emit();
  }

  onSlideChange() {
    
      this.activeSlide = this.swiper?.swiperRef.activeIndex + 1;
      this.counterText = `${this.activeSlide} / ${this.totalSlides}`;
      this.slideChange.emit(this.activeSlide);
      this.cdr.detectChanges();
    
    
  }

}
