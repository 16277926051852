import { Component, OnInit, OnDestroy, ComponentFactoryResolver, Injector } from "@angular/core";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowLoginMessageData, ShowMobileFilterMessageData, ShowYesNoPopupMessageData, ShowContactAgentMessageData, CloseAccountMessageData, ShowTextPopupMessageData, ShowLoaderMessageData, ShowRatePopupMessageData, ConfirmAccountMessageData } from "src/app/infrastructure/message.data.types";
import { PopupComponent } from "src/app/controls/popup/popup.component";
import { LoadLazy } from "src/app/lazy.loader";
import { DynamicComponentContainerComponentBase, DYNAMIC_COMPONENT_TYPES, IDynamicComponentParameters } from "../component.base.classes/dynamic.component.container.component.base";
import { Location, NgIf } from "@angular/common";
import { LoaderComponent } from "../loader/loader.component";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { Router } from "@angular/router";
import { UrlHelpersService } from "src/app/services/url.helpers.module/url.helpers.service";


@Component({
    selector: 'overlay-container',
    templateUrl: './overlay-container.component.html',
    styleUrls: ['./overlay-container.component.scss'],
    imports: [NgIf, LoaderComponent]
})

export class OverlayContainerComponent extends DynamicComponentContainerComponentBase implements OnInit, OnDestroy {
    private canUserClose: boolean = true;
    private _isDynamicVisible: boolean = false;
    private _isLoaderVisible: boolean = false;
    private previousIsDynamicVisible = false;
    private previousSecondaryOverlay = false;
    private previousCanUserClose = true;

    isOverlayVisible: boolean = false;
    message: string = "";

    set isDynamicVisible(value: boolean) {
        this.isOverlayVisible = value;
        if (this._isDynamicVisible === value) { return; }
        this._isDynamicVisible = value;
    }
    get isDynamicVisible(): boolean {
        return this._isDynamicVisible;
    }

    set isLoaderVisible(value: boolean) {
        if (value === this.isLoaderVisible) { return; }
        if (value) {
            this.previousIsDynamicVisible = this.isDynamicVisible;
            this.previousCanUserClose = this.canUserClose;
            this.isDynamicVisible = false;
            this.isOverlayVisible = true;
            this._isLoaderVisible = true;
        }
        else {
            this._isLoaderVisible = false;
            this.isDynamicVisible = this.previousIsDynamicVisible;
            this.canUserClose = this.previousCanUserClose;
        }
    };
    get isLoaderVisible(): boolean {
        return this._isLoaderVisible;
    }


    private showLoginSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_LOGIN,
        On: (data: ShowLoginMessageData) => {
            this.ShowLoginOrRegister(data);
        }
    };


    private showMobileFilterSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_MOBILE_FILTER,
        On: (data: ShowMobileFilterMessageData) => {
            this.ShowMobileFilter(data);
        }
    }

    private showContactAgentSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_CONTACT_AGENT,
        On: (data: ShowContactAgentMessageData) => {
            this.ShowContactAgent(data);
        }
    }

    private showCloseAccountSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_CLOSE_ACCOUNT,
        On: (data: CloseAccountMessageData) => {
            this.ShowCloseAccount(data);
        }
    }

    private showConfirmAccountSubscriber: ISubscriber = {
      messageType: MESSAGE_TYPES.SHOW_CONFIRM_ACCOUNT,
      On: (data: ConfirmAccountMessageData) => {
          this.ShowConfirmAccount(data);
      }
  }


    private showRatePopupSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_RATE_POPUP,
        On: (data: ShowRatePopupMessageData) => {
            this.ShowRatePopup(data);
        }
    }

    private showLoaderMessageSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_LOADER,
        On: (data: ShowLoaderMessageData) => {
            this.message = data.message;
            if (data.isTrue && this.applicationStateService.disableSearchLoader) { return; }
            if (this.platformService.IsBrowserPlatform) {
                this.canUserClose = !data.isTrue;
                this.isLoaderVisible = data.isTrue;
            }
        }
    }

    private closePopupSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.CLOSE_POPUP,
        On: () => {
            if (this.isDynamicVisible) {
                this.HideAndDestroy();
                this.urlHelpersService.RemovePopupVisible();
            }
        }
    };

    private navigatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.NAVIGATED,
        On: () => {
            if (!this.applicationStateService.allowPopupInNavigation) {
                if (this.isDynamicVisible) {
                    this.HideAndDestroy();
                }
            }
            else {
                this.applicationStateService.allowPopupInNavigation = false;
            }
        }
    }

    private yesNoPopupSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_YES_NO_POPUP,
        On: async (data: ShowYesNoPopupMessageData) => {
            var c = await LoadLazy.YesNoPopupComponent();
            this.ShowPopup({
                componentType: DYNAMIC_COMPONENT_TYPES.YES_NO_POPUP,
                dynamicComponentResolver: (resolver: ComponentFactoryResolver) => {
                    return resolver.resolveComponentFactory(c);
                },
                componentParameters: data.data
            });
        }
    }

    private showTextPopupSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_TEXT_POPUP,
        On: async (data: ShowTextPopupMessageData) => {
            var c = await LoadLazy.TextPopupComponent();
            this.ShowPopup({
                componentType: DYNAMIC_COMPONENT_TYPES.TEXT_POPUP,
                dynamicComponentResolver: (resolver: ComponentFactoryResolver) => {
                    return resolver.resolveComponentFactory(c);
                },
                componentParameters: data
            })
        }
    }

    constructor(
        resolver: ComponentFactoryResolver,
        injector: Injector,
        private messenger: Messenger,
        private router: Router,
        private location: Location,
        private urlHelpersService: UrlHelpersService,
        private platformService: PlatformHelpersService,
        private applicationStateService: ApplicationStateService) {
        super(resolver, injector);

        this.messenger.Subscribe(this.showContactAgentSubscriber);
        this.messenger.Subscribe(this.showLoginSubscriber);
        this.messenger.Subscribe(this.showCloseAccountSubscriber);
        this.messenger.Subscribe(this.showConfirmAccountSubscriber)
        this.messenger.Subscribe(this.closePopupSubscriber);
        this.messenger.Subscribe(this.showLoaderMessageSubscriber);
        this.messenger.Subscribe(this.navigatedSubscriber);
        this.messenger.Subscribe(this.yesNoPopupSubscriber);
        this.messenger.Subscribe(this.showMobileFilterSubscriber);
        this.messenger.Subscribe(this.showTextPopupSubscriber);
        this.messenger.Subscribe(this.showRatePopupSubscriber);
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.showContactAgentSubscriber);
        this.messenger.Unsubscribe(this.showLoginSubscriber);
        this.messenger.Unsubscribe(this.showCloseAccountSubscriber);
        this.messenger.Unsubscribe(this.showConfirmAccountSubscriber)
        this.messenger.Unsubscribe(this.closePopupSubscriber);
        this.messenger.Unsubscribe(this.showLoaderMessageSubscriber);
        this.messenger.Unsubscribe(this.navigatedSubscriber);
        this.messenger.Unsubscribe(this.yesNoPopupSubscriber);
        this.messenger.Unsubscribe(this.showMobileFilterSubscriber);
        this.messenger.Unsubscribe(this.showTextPopupSubscriber);
        this.messenger.Subscribe(this.showRatePopupSubscriber);
    }

    OnClickedOverlay(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.canUserClose) {
            this.HideAndDestroy();
        }
    }

    private HideAndDestroy() {
        this.isDynamicVisible = false;
        //this.navigationService.TogglePopupVisibleInUri(false);
        if (this.currentComponent) {
            this.CleanUp();
        }
    }

    private async ShowLoginOrRegister(data: ShowLoginMessageData) {
        var c = await LoadLazy.LoginComponent();
        var popupDynamicComponentParameters: IDynamicComponentParameters = {
            dynamicComponentResolver: (resolver) => {
                return resolver.resolveComponentFactory(c);
            },
            componentType: DYNAMIC_COMPONENT_TYPES.LOGIN,
            componentParameters: data
        };
        this.ShowPopup(popupDynamicComponentParameters);
        
    }

    private ShowMobileFilter(data: ShowMobileFilterMessageData) {
        var popupDynamicComponentParameters: IDynamicComponentParameters = {
            componentType: DYNAMIC_COMPONENT_TYPES.SEARCH_MOBILE,
            componentParameters: data.searchComponentModel,
            resolvedComponent: data.resolvedSearchMobileComponent
        };
        this.ShowPopup(popupDynamicComponentParameters);
        var popupComponent = this.currentComponent.instance as PopupComponent;
        if (popupComponent.currentComponent && popupComponent.currentComponent.instance) {
            var instance = popupComponent.currentComponent.instance;
            instance.heading = data.heading;
            instance.mode = data.mode;
        }
    }

    private ShowContactAgent(data: ShowContactAgentMessageData) {
        var popupDynamicComponentParameters: IDynamicComponentParameters = {
            resolvedComponent: data.resolvedContactAgentComponent,
            componentType: DYNAMIC_COMPONENT_TYPES.CONTACT_AGENT,
            componentParameters: data
        };
        this.ShowPopup(popupDynamicComponentParameters);
    }

    private ShowCloseAccount(data: CloseAccountMessageData) {
        var popupDynamicComponentParameters: IDynamicComponentParameters = {
            componentType: DYNAMIC_COMPONENT_TYPES.CLOSE_ACCOUNT,
            resolvedComponent: data.resolvedCloseAccountComponent,
            componentParameters: {}
        };
        this.ShowPopup(popupDynamicComponentParameters);
    }


    private ShowConfirmAccount(data: ConfirmAccountMessageData) {
      var popupDynamicComponentParameters: IDynamicComponentParameters = {
          componentType: DYNAMIC_COMPONENT_TYPES.CLOSE_ACCOUNT,
          resolvedComponent: data.resolvedConfirmAccountComponent,
          componentParameters: {}
      };
      this.ShowPopup(popupDynamicComponentParameters);
  }

    private ShowRatePopup(data: ShowRatePopupMessageData) {
        var popupDynamicComponentParameters: IDynamicComponentParameters = {
            componentType: DYNAMIC_COMPONENT_TYPES.CLOSE_ACCOUNT,
            resolvedComponent: data.resolvedRateComponent,
            componentParameters: data
        };
        this.ShowPopup(popupDynamicComponentParameters);
    }

    private ShowPopup(popupDynamicComponentParameters: IDynamicComponentParameters) {
        this.isOverlayVisible = true;
        this.isDynamicVisible = true;
        this.hasLoaded = false;
        // Create popup
        this.dynamicComponentParameters = {
            componentType: null,
            componentParameters: null,
            dynamicComponentResolver: (resolver: ComponentFactoryResolver) => {
                return resolver.resolveComponentFactory(PopupComponent)
            }
        };

        // Create contained component
        var popupComponent = this.currentComponent.instance as PopupComponent;
        popupComponent.dynamicComponentParameters = popupDynamicComponentParameters;
        if (popupComponent.currentComponent && popupComponent.currentComponent.instance) {
            this.canUserClose = popupComponent.currentComponent.instance.canUserClose;
        }
        else {
            this.canUserClose = true;
        }
        this.previousCanUserClose = this.canUserClose;
        popupComponent.Size();
        this.TogglePopupVisibleInUri(true);
        
    }

    skipGoingBackForPopup: boolean = false;
    
    TogglePopupVisibleInUri(isVisible: boolean): void {
        if (this.platformService.IsBrowserPlatform) {
            if (isVisible) {
                this.urlHelpersService.AddQueryToLocation("popupvisible", "true");
            }
            else {
                var url = this.urlHelpersService.GetParsedUrl(this.urlHelpersService.GetCurrentUrl());
                if (url.queryObject.popupvisible) {
                    if (!this.skipGoingBackForPopup) {
                        this.location.back();
                    }
                    else {
                        var path = this.urlHelpersService.RemoveQueriesFromPath(this.location.path(), ["popupvisible"]);
                        this.location.replaceState(path);
                    }
                }
                
            }
        }
    }
}



