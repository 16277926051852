import { Component, OnDestroy, OnInit, ElementRef } from "@angular/core";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowSecondaryOverlayMessageData } from "src/app/infrastructure/message.data.types";
import { NgIf } from "@angular/common";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";

@Component({
    selector: 'secondary-overlay',
    templateUrl: 'secondary-overlay.component.html',
    styleUrls: ['secondary-overlay.component.scss'],
    imports: [NgIf]
})

export class SecondaryOverlayComponent implements OnInit, OnDestroy {
    isSecondaryOverlayVisible: boolean = false;


    private previousZIndex: string;
    private previousPosition: string;
    private currentHighlighted: ElementRef;

    private navigatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.NAVIGATED,
        On: () => {
            if (this.isSecondaryOverlayVisible) {
                this.isSecondaryOverlayVisible = false;
            }
        }
    }

    private showOverlaySubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.SHOW_SECONDARY_OVERLAY,
        On: (data: ShowSecondaryOverlayMessageData) => {
            this.ResetCurrentHighlighted();
            this.isSecondaryOverlayVisible = data.isVisible;
            this.currentHighlighted = data.elementRef;
            if (data.elementRef) {
                var styles = this.browserAbstractionService.Window.getComputedStyle(data.elementRef.nativeElement, null);
                this.previousZIndex = styles.getPropertyValue("z-index");
                this.previousPosition = styles.getPropertyValue("position");
                data.elementRef.nativeElement.style.position = "relative";
                data.elementRef.nativeElement.style.zIndex = "5";
                if (data.customCssText) {
                    data.elementRef.nativeElement.style.cssText += data.customCssText;
                }
            }
        }
    }

    constructor(
        private browserAbstractionService: PlatformHelpersService,
        private messenger: Messenger
    ) { }

    ngOnInit() {
        this.messenger.Subscribe(this.showOverlaySubscriber);
        this.messenger.Subscribe(this.navigatedSubscriber);
    }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.showOverlaySubscriber);
        this.messenger.Unsubscribe(this.navigatedSubscriber);
    }

    private ResetCurrentHighlighted() {
        if (this.currentHighlighted) {
            if (this.currentHighlighted.nativeElement.style.cssText.toLowerCase().indexOf("absolute") > 0) {
                this.currentHighlighted.nativeElement.style.cssText = "";
            }
            this.currentHighlighted.nativeElement.style.zIndex = this.previousZIndex;
            this.currentHighlighted.nativeElement.style.position = this.previousPosition;
        }
    }

    OnClickedSecondaryOverlay(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isSecondaryOverlayVisible = false;
        this.ResetCurrentHighlighted();
        this.messenger.Send({
            messageType: MESSAGE_TYPES.CLOSE_POPUP,
            messageData: null
        });
    }
}


