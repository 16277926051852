import { Component, Input, Output, EventEmitter, NgModule, ViewEncapsulation } from "@angular/core";
import { ActionTrackingDirective } from "../../directives/action-tracking.directive";

@Component({
    selector: 'check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [ActionTrackingDirective]
})
export class CheckBoxComponent {
    _isSelected: boolean;

    @Input() caption: string;
    @Input() actionTrackingLabel: string;

    get isSelected(): boolean {
        return this._isSelected;
    }
    @Input() set isSelected(value: boolean) {
        if (this._isSelected != value) {
            if (value == undefined) { this._isSelected = false }
            else { this._isSelected = value; }
            this.isSelectedChange.emit(value);
        }
    }

    @Output() isSelectedChange = new EventEmitter<boolean>();

    constructor() {
        this._isSelected = false;
    }

    OnClicked(isChecked: boolean) {
        this.isSelected = isChecked;
    }
}
