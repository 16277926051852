import { Injectable, ComponentFactoryResolver, OnInit } from "@angular/core";
import { DoubleClickAdModel } from "./model";
import { KeyValuePair } from "src/app/infrastructure/types";
import { PlatformHelpersService } from "../platform.helpers.module/platform.helpers.service";
import { LoggingService } from "../logging.module/logging.service";
import { ApplicationStateService } from "../application.state.module/application.state.service";
import { ApplicationResourcesService } from "../application.resources.module/application.resources.service";
import { BreakpointService } from "../breakpoint.module/breakpoint.service";

@Injectable({providedIn: 'root'})
export class DoubleClickAdsService implements OnInit {

  constructor(
    private platformService: PlatformHelpersService,
    private loggingService: LoggingService,
    private applicationStateService: ApplicationStateService,
    private applicationResourcesService: ApplicationResourcesService,
    private breakpointService: BreakpointService,
) {
  
 }

  private adServer: string = this.platformService.adsProvider;

    interstitialLoaded:boolean = false;

     public allAdDefinitions = [
        // {adProvider:this.adServer, page:"search_results", adType: "static", mode: "sra", adDeviceType:"desktop", adPosition:"header_banner", slotName:"/64165495/OKO_Desktop_SearchResult_Topbanner_AboveFold", slotId:"div-gpt-ad-1589899135295-0" , size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy", adDeviceType:"desktop", adPosition:"result_incontent_1", slotName:"/64165495/OKO_Desktop_SearchResult_Topbanner", slotId:"div-gpt-ad-1507296862622-0", size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"result_incontent_2", slotName:"/64165495/OKO_search_desktop_728x90", slotId:"div-gpt-ad-1561019256230-0" ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"result_incontent_3", slotName:"/64165495/OKO_Desktop_SearchResult_After8th", slotId:"div-gpt-ad-1590833994508-0" ,size:[{width: "728", height: "90"}], minHeight:0 , lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"result_incontent_4", slotName:"/64165495/OKO_Desktop_SearchResult_Midbanner", slotId:"div-gpt-ad-1507296862622-1"  ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"result_vrec_1", slotName:"/64165495/OKO_Desktop_SearchResult_RHSMPU_Large", slotId:"div-gpt-ad-1576504188875-0" , size:[{width: "300", height: "600"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy", adDeviceType:"desktop", adPosition:"result_incontent_5", slotName:"/64165495/OKO_Desktop_SearchResult_Bottombanner", slotId:"div-gpt-ad-1515507325634-0" ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        // REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"search_results", adType: "anchor", mode: "lazy", adDeviceType:"desktop", adPosition:"sticky_banner", slotName:"/64165495/insticator_Placebuzz_anchor", slotId:"div-gpt-ad-1681985312156-0" ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        
        // {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy", adDeviceType:"desktop", adPosition:"fifth_banner", slotName:"/64165495/OKO_Desktop_SearchResult_fifth_banner", slotId:"div-gpt-ad-1659343945923-0"  ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:400},
        // {adProvider:this.adServer, page:"search_results", adType: "static",mode: "lazy", adDeviceType:"desktop", adPosition:"sixth_banner", slotName:"/64165495/OKO_Desktop_SearchResult_sixth_banner", slotId:"div-gpt-ad-1659343985575-0"  ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:400},
        //{adProvider:"admanager", page:"search_results", adType: "interstitial", adDeviceType:"desktop", adPosition:"interstitial_banner", slotName:"/64165495/placebuzz_interstitial", slotId:"no-div-slot" , size:[{width: "0", height: "0"}], minHeight:0, lazyloadMargin:400},

        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"mobile", adPosition:"result_header", slotName:"/64165495/OKO_Mobile_SearchResults_Banner_Top_AboveFold", slotId:"div-gpt-ad-1601653559062-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}] , minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"mobile", adPosition:"result_incontent_1", slotName:"/64165495/OKO_Mobile_SearchResults_MPU_Top", slotId:"div-gpt-ad-1515509294148-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"mobile", adPosition:"result_incontent_2", slotName:"/64165495/OKO_Mobile_SearchResults_MPU", slotId:"div-gpt-ad-1507298013183-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy", adDeviceType:"mobile", adPosition:"result_incontent_3", slotName:"/64165495/OKO_search_mob_300x250", slotId:"div-gpt-ad-1560333528153-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",  adDeviceType:"mobile", adPosition:"result_incontent_4", slotName:"/64165495/OKO_Mobile_SearchResults_MPU_After8th", slotId:"div-gpt-ad-1507298013183-1", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"search_results", adType: "anchor", mode: "lazy",  adDeviceType:"mobile", adPosition:"sticky_banner", slotName:"/64165495/Mobile_Search_Results_Sticky_Banner", slotId:"div-gpt-ad-1601563088942-0", size:[{width: "320", height: "50"},{width: "320", height: "100"}], lazyloadMargin:250},
        // {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy", adDeviceType:"mobile", adPosition:"footer_banner", slotName:"/64165495/OKO_Mobile_SearchResults_footer_banner", slotId:"div-gpt-ad-1659346623574-0" ,size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250},
        // {adProvider:this.adServer, page:"search_results", adType: "static",mode: "lazy", adDeviceType:"mobile", adPosition:"fifth_banner", slotName:"/64165495/OKO_Mobile_SearchResults_fifth_banner", slotId:"div-gpt-ad-1659344056539-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:400},
        // {adProvider:this.adServer, page:"search_results", adType: "static", mode: "lazy",adDeviceType:"mobile", adPosition:"sixth_banner", slotName:"/64165495/OKO_Mobile_SearchResults_sixth_banner", slotId:"div-gpt-ad-1659344100931-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:400},
        //{adProvider:"admanager", page:"search_results", adType: "interstitial", adDeviceType:"mobile", adPosition:"interstitial_banner", slotName:"/64165495/placebuzz_interstitial", slotId:"no-div-slot" , size:[{width: "0", height: "0"}], minHeight:0, lazyloadMargin:400},

        //REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "lazy", adDeviceType:"desktop", adPosition:"property_header", slotName:"/64165495/OKO_Desktop_Details_Banner_AboveFold", slotId:"div-gpt-ad-1589898814546-0", size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250 },
        //REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "sra", adDeviceType:"desktop", adPosition:"first_banner", slotName:"/64165495/OKO_Desktop_Details_Banner", slotId:"div-gpt-ad-1507298621917-0", size:[{width: "728", height: "90"}] , minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"property_incontent_1", slotName:"/64165495/OKO_Desktop_Details_Banner_2", slotId:"div-gpt-ad-1601562615377-0", size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "lazy", adDeviceType:"desktop", adPosition:"property_vrec_1", slotName:"/64165495/OKO_Desktop_Details_SkyScraper", slotId:"div-gpt-ad-1507298621917-1", size:[{width: "300", height: "600"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        //REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",  adDeviceType:"desktop", adPosition:"property_incontent_2", slotName:"/64165495/OKO_Desktop_Details_Bottom_MPU", slotId:"div-gpt-ad-1573657608679-0",size:[{width: "728", height: "90"} ], minHeight:0, lazyloadMargin:250},
        //REMOVED AS PART OF IMPROVEMENTS {adProvider:this.adServer, page:"property_details", adType: "anchor", mode: "lazy", adDeviceType:"desktop", adPosition:"sticky_banner", slotName:"/64165495/insticator_Placebuzz_anchor", slotId:"div-gpt-ad-1681985312156-0" ,size:[{width: "728", height: "90"}], minHeight:0, lazyloadMargin:250},
        // {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",adDeviceType:"desktop", adPosition:"fourth_banner", slotName:"/64165495/OKO_Desktop_Details_fourth_banner", slotId:"div-gpt-ad-1659344910779-0",size:[{width: "728", height: "90"} ], minHeight:0, lazyloadMargin:400},
        // {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",adDeviceType:"desktop", adPosition:"fifth_banner", slotName:"/64165495/OKO_Desktop_Details_fifth_banner", slotId:"div-gpt-ad-1659344952720-0",size:[{width: "728", height: "90"} ], minHeight:0, lazyloadMargin:250},
        //{adProvider:"admanager", page:"property_details", adType: "interstitial", adDeviceType:"desktop", adPosition:"interstitial_banner", slotName:"/64165495/placebuzz_interstitial", slotId:"no-div-slot" , size:[{width: "0", height: "0"}], minHeight:0, lazyloadMargin:400},

        {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "lazy", adDeviceType:"mobile", adPosition:"property_header", slotName:"/64165495/OKO_Mobile_Details_TopBanner_AboveFold", slotId:"div-gpt-ad-1589898948898-0" ,size:[{width: "320", height: "100"}, {width: "320", height: "50"}], minHeight:0, lazyloadMargin:250},
        // {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "sra", adDeviceType:"mobile", adPosition:"first_banner", slotName:"/64165495/OKO_Mobile_Details_TopBanner", slotId:"div-gpt-ad-1507298208351-1", size:[{width: "320", height: "100"},{width: "320", height: "50"}], minHeight:0, lazyloadMargin:250},
        {adProvider:this.adServer, page:"property_details", adType: "static",  mode: "lazy", adDeviceType:"mobile", adPosition:"property_incontent_1", slotName:"/64165495/OKO_Mobile_Details_MPU", slotId:"div-gpt-ad-1507298208351-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}] , minHeight:0, lazyloadMargin:0},
        {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",  adDeviceType:"mobile", adPosition:"property_incontent_2", slotName:"/64165495/OKO_Mobile_Details_Bottom_MPU", slotId:"div-gpt-ad-1573657221502-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0 , lazyloadMargin:250},
        {adProvider:this.adServer, page:"property_details", adType: "anchor",  mode: "lazy", adDeviceType:"mobile", adPosition:"sticky_banner", slotName:"/64165495/Mobile_Details_Sticky_Banner", slotId:"div-gpt-ad-1601711594534-0", size:[{width: "320", height: "50"}, {width: "320", height: "100"}] , minHeight:0, lazyloadMargin:250},
        // {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",adDeviceType:"mobile", adPosition:"fourth_banner", slotName:"/64165495/OKO_Mobile_Details_fourth_banner", slotId:"div-gpt-ad-1659344564786-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        // {adProvider:this.adServer, page:"property_details", adType: "static", mode: "lazy",adDeviceType:"mobile", adPosition:"fifth_banner", slotName:"/64165495/OKO_Mobile_Details_fifth_banner", slotId:"div-gpt-ad-1659344704246-0", size:[{width: "336", height: "280"},{width: "300", height: "250"}], minHeight:0, lazyloadMargin:250 },
        //{adProvider:"admanager", page:"property_details", adType: "interstitial", adDeviceType:"mobile", adPosition:"interstitial_banner", slotName:"/64165495/placebuzz_interstitial", slotId:"no-div-slot" , size:[{width: "0", height: "0"}], minHeight:0, lazyloadMargin:400}
      ];

    public gptAdSlots:{[key:string]:any}=[];

    public gptPageAdSlots:KeyValuePair<string, any>[]=[];

    
  ngOnInit(): void {
    
    if (this.platformService.IsBrowserPlatform)
    this.breakpointService.getBreakpoint();
  
  }

    InitialisePageAds(pageName:string, targetingType:string){

      if (!this.platformService.GoogleTag || !this.platformService.GoogleTag.cmd) {
        this.loggingService.LogToDevConsole("GoogleTag is not initialized properly.");
        return;
      }

      var self = this;

      if (this.platformService.IsBrowserPlatform && this.platformService.adsProvider != "admanager") {
        return;
      }

      this.loggingService.LogToDevConsole("double.click.service -> clearing Page Ad Slots Array"); 
      this.gptPageAdSlots = [];
      
      var adPlatform = this.getDisplayMode();

      var pageAds = this.GetAdsForPage(pageName, adPlatform);
      this.loggingService.LogToDevConsole("double.click.service -> Found " + pageAds.length + " ads for " + pageName + " on " + adPlatform + " platform."); 

      var slot:any;

      self.platformService.GoogleTag.cmd.push(() => {
        self.platformService.GoogleTag.pubads().setTargeting('pagetype', targetingType);
        self.platformService.GoogleTag.pubads().setTargeting('platform', this.applicationStateService.isMobileApp ? 'mobileapp' : 'web');

        if (!this.isLive()) {
            self.platformService.GoogleTag.pubads().setTargeting('debug', 'true');
            self.platformService.GoogleTag.pubads().setTargeting('environment', 'qa');
        }

        pageAds.forEach(ad => {
          this.loggingService.LogToDevConsole("double.click.service -> [o] Registering slot " + ad.page + " - " + ad.adPosition + "(SlotId: " + ad.slotId + " - SlotName: " + ad.slotName + ")");
        
          var size: Array<Array<number>>= ad.size.map(m=>{return [parseInt(m.width),parseInt(m.height)]});

          if (ad.adType == "static"){
            slot = self.platformService.GoogleTag.defineSlot(ad.slotName, size, ad.slotId).addService(self.platformService.GoogleTag.pubads()).setCollapseEmptyDiv(true,true);
          
            var mapping = self.platformService.GoogleTag.sizeMapping();
            if (ad.sizeMapping){
              ad.sizeMapping.forEach(element => {
                mapping.addSize(element.resolution, [element.serve])
              });
              slot.defineSizeMapping(mapping);
            }
          }
          else if (ad.adType=="anchor"){
            slot = self.platformService.GoogleTag.defineOutOfPageSlot(ad.slotName, self.platformService.GoogleTag.enums.OutOfPageFormat.BOTTOM_ANCHOR);

            if (slot){
              slot.addService(self.platformService.GoogleTag.pubads());
            }
          }
          else if (ad.adType == "interstitial"){
            slot = self.platformService.GoogleTag.defineOutOfPageSlot(ad.slotName, self.platformService.GoogleTag.enums.OutOfPageFormat.INTERSTITIAL);

            if (slot){
              slot.addService(self.platformService.GoogleTag.pubads());
            }
          }
          this.loggingService.LogToDevConsole("double.click.service -> [o] Pushing slot " + ad.slotId + " to Page Ad Slots Array");
          this.gptPageAdSlots.push(new KeyValuePair<string, any>(ad.slotId, slot));
        });

        this.loggingService.LogToDevConsole("*** double.click.service -> Page Ad Slots Array now has " + this.gptPageAdSlots.length);

        self.platformService.GoogleTag.pubads().enableSingleRequest();
        
        self.platformService.GoogleTag.pubads().enableLazyLoad({
            fetchMarginPercent: 100,
            renderMarginPercent: 10,
        });

        self.platformService.GoogleTag.enableServices();   
      });
    }


    DisplayAd(slotId:string):any{
      try {

        this.loggingService.LogToDevConsole("double.click.service -> Attempting to Display Ad " + slotId);
        var slot = this.gptPageAdSlots.find(p=>p.key == slotId);
      
        if (!slot){
          this.loggingService.LogErrorToDevConsole("double.click.service -> [o] COULD NOT FIND " + slotId + " IN GPT Adslots Array");
          console.log(this.gptPageAdSlots);
        }

        var self = this;

          if (this.platformService.IsBrowserPlatform) {
                self.platformService.GoogleTag.cmd.push(() => {
                  this.loggingService.LogToDevConsole("double.click.service -> [o] Displaying " + slotId);
                  self.platformService.GoogleTag.display(slotId);            
              });
          }
          else{
              this.loggingService.LogToDevConsole("doubleclick.ads.service -> Error Displaying ad " + slotId);
          }
      }
      catch (error) {
          this.loggingService.LogError({ title: "Error Displaying Ad", error: error });
      }
  }



    RegisterSlot(slotId: string): any {

      var self = this;
      var slot:any;

      if (self.platformService.IsBrowserPlatform) {

        var ad = this.GetAdDefinition(slotId);

        self.platformService.GoogleTag.cmd.push(() => {
          
          self.platformService.GoogleTag.pubads().setTargeting('platform', this.applicationStateService.isMobileApp ? 'mobileapp' : 'web');

          if (!this.isLive()) {
              self.platformService.GoogleTag.pubads().setTargeting('debug', 'true');
              self.platformService.GoogleTag.pubads().setTargeting('environment', 'qa');
          }

          this.loggingService.LogToDevConsole("double.click.service -> [o] Registering slot " + slotId);
          var size: Array<Array<number>>= ad.size.map(m=>{return [parseInt(m.width),parseInt(m.height)]});

          slot = self.platformService.GoogleTag.defineSlot(ad.slotName, size, slotId).addService(self.platformService.GoogleTag.pubads()).setCollapseEmptyDiv(true,true);
          var mapping = self.platformService.GoogleTag.sizeMapping();
          if (ad.sizeMapping){
            ad.sizeMapping.forEach(element => {
              mapping.addSize(element.resolution, [element.serve])
            });
            slot.defineSizeMapping(mapping);
          }

          self.platformService.GoogleTag.enableServices();
          self.platformService.GoogleTag.display(slotId);

        });

        return slot;

      }
      else{
        this.loggingService.LogToDevConsole("double.click.service -> NOT YET READY " + slotId);
        return null;
      }
  }

  
    DestroySlot(slotId){
    
      this.platformService.GoogleTag.cmd.push(() => {
        this.platformService.GoogleTag.destroySlots([slotId]);
      });
    
    }

    ClearAds():void{
        try {

        if (this.platformService.IsBrowserPlatform && this.applicationResourcesService.GetApplicationSetting("ShowAds", true) && this.platformService.adsProvider=="admanager") {
          this.platformService.GoogleTag.cmd.push(() => { 
            this.platformService.GoogleTag.destroySlots(); 
            this.platformService.GoogleTag.pubads().refresh();
          });
          this.loggingService.LogToDevConsole("double.click.service -> Clearing Ad Manager ads");
        }
        }
        catch(error){
            this.loggingService.LogError({ title: "Error Clearing Ads", error: error });
        }
    }


    ClearAd(slot):void{
        try {
            if (this.platformService.IsBrowserPlatform && this.platformService.adsProvider=="admanager") {
            this.platformService.GoogleTag.cmd.push(() => { this.platformService.GoogleTag.destroySlots([slot]); });
            this.loggingService.LogToDevConsole("double.click.service -> Clearing ad");
            }
        }
        catch(error){
            this.loggingService.LogError({ title: "Error Clearing Ads", error: error });
        }
    }


    GetAdDefinition(slotId:string): DoubleClickAdModel{
      var adDefinition = this.allAdDefinitions.find(d=>d.slotId===slotId);
      return adDefinition;
    }

    
    GetAdsForPage(pageName:string, adPlatform:string): DoubleClickAdModel[]{
      return this.allAdDefinitions.filter(p=>p.page==pageName && p.adDeviceType==adPlatform && p.mode=="sra");
    }

    GetAdSlot(page:string, adPosition:string){
      
        var adDeviceType = this.getDisplayMode();

        var adProvider = this.platformService.adsProvider;

        var adDefinition = this.allAdDefinitions.find(d=>d.adProvider==adProvider && d.page==page && d.adDeviceType==adDeviceType && d.adPosition==adPosition);
        return adDefinition;
    }


    private isLive() {
        return this.platformService.IsLiveUrl;
    }

    getDisplayMode() {

      if (!this.platformService.IsBrowserPlatform){
        return "";
      }
      var adBreakpoint = this.breakpointService.getBreakpoint();
    
      var adDeviceType = "";
    
      switch (adBreakpoint) {
        case 'mobile':
          adDeviceType = "mobile";
          break;
        case 'tablet':
          adDeviceType = "mobile";
          break;
        case 'desktop':
          adDeviceType = "desktop";
          break;
        default:
          adDeviceType = "desktop";
          break;
      }
      return adDeviceType;
    }


}


