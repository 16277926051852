import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { SafeUrlPipe } from 'src/app/pipes/safe.url.pipe';



@Component({
    selector: 'affiliate',
    templateUrl: 'affiliate.component.html',
    styleUrls: ['affiliate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SafeUrlPipe]
})


export class AffiliateComponent implements OnInit {
    @Input() caption: string;
    @Input() affiliateLogoClass: string;
    @Input() link: string;

    ngOnInit() { }
}



