import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PbIconComponent } from '../pb-icon/pb-icon.component';
import { Location, NgIf } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.module/authorization.service';
import { MeStateChangedMessageData, ShowLoginMessageData } from 'src/app/infrastructure/message.data.types';
import { ApplicationStateService } from 'src/app/services/application.state.module/application.state.service';
import { Messenger } from 'src/app/services/messenger.module/messenger';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { HistoryService } from 'src/app/services/history.service.module/history.service';
import { Me } from 'src/app/infrastructure/model/me';
import { ISubscriber } from 'src/app/services/messenger.module/model/model';

@Component({
  selector: 'pb-navbar',
  imports: [PbIconComponent, NgIf, RouterLink],
  templateUrl: './pb-navbar.component.html',
  styleUrls: ['./pb-navbar.component.scss'],
  providers: [AuthorizationService]
})
export class PbNavbarComponent implements OnInit, OnDestroy {


  constructor(
    private applicationStateService: ApplicationStateService,
    private messenger: Messenger,
    private router: Router,
    private historyService: HistoryService,
    private location: Location
  ){

    this.hasBackStack = this.historyService.canGoBack();
    this.isMobileApp = this.applicationStateService.isMobileApp;

  }
    

  isMenuOpen = false;
  isMoreMenuOpen = false;
  isLoggedIn = false;
  isProfileMenuOpen = false;
  initial = "";
  hasBackStack: boolean = false;
  isMobileApp: boolean = false;
  
  private meStateChangedSubscriber: ISubscriber = {
    messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
    On: (data: MeStateChangedMessageData) => {
        if (data) {
            this.OnMeStateChanged(data.me);
        }
    }
  }

  private navigatedSubscriber: ISubscriber = {
    messageType: MESSAGE_TYPES.NAVIGATED,
    On: () => {
        this.hasBackStack = this.historyService.canGoBack();
    }
}

  ngOnInit() {
    this.messenger.Subscribe(this.meStateChangedSubscriber);
    this.messenger.Subscribe(this.navigatedSubscriber);
    if (this.applicationStateService.me){
        this.OnMeStateChanged(this.applicationStateService.me);
    }
  }

  ngOnDestroy(): void {
      this.messenger.Unsubscribe(this.meStateChangedSubscriber);
      this.messenger.Unsubscribe(this.navigatedSubscriber);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
  
    if (!target.closest('.navbar') && !target.closest('.dropdown')) {
      this.isMenuOpen = false; 
      this.isMoreMenuOpen = false; 
      this.isProfileMenuOpen = false;
    }
  }

  onBackClicked(){
    this.location.back();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleMoreMenu() {
    this.isProfileMenuOpen = false;
    this.isMoreMenuOpen = !this.isMoreMenuOpen;
  }

  toggleProfileMenu(){
    this.isMoreMenuOpen = false;
    this.isProfileMenuOpen = !this.isProfileMenuOpen;
  }

  closeProfileMenu() {
    this.isProfileMenuOpen = false;
    this.isMenuOpen = false;
  }

  closeMoreMenu() {
    this.isMoreMenuOpen = false;
    this.isMenuOpen = false;
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.closeMoreMenu();
    this.closeProfileMenu();
  }

  onLoginClick() {
    this.closeMenu();
    this.applicationStateService.StorePostLoginReturnUrl("");
        var loginMessageData: ShowLoginMessageData = {
            showOverlay: true,
            isRegistration: false
        };
    this.messenger.Send({
      messageType: MESSAGE_TYPES.SHOW_LOGIN,
      messageData: loginMessageData
    });
  }

  onRegisterClick() {
    this.closeMenu();
    this.applicationStateService.StorePostLoginReturnUrl("");
        var loginMessageData: ShowLoginMessageData = {
            showOverlay: true,
            isRegistration: true
        };
    this.messenger.Send({
      messageType: MESSAGE_TYPES.SHOW_LOGIN,
      messageData: loginMessageData
    });
  }


  onFavouritesClick(){
    this.closeMenu();

    if (this.isLoggedIn){
      this.router.navigate(['/favourites']);
      return;
    }


    var loginMessageData: ShowLoginMessageData;
    this.applicationStateService.StorePostLoginReturnUrl("/favourites");
    loginMessageData = {
        showOverlay: true,
        isRegistration: true,
        explicitRedirectUrl: "/favourites",
        registerText: [
            "Placebuzz has much more to offer",
            "Sign up to",
            "keep",
            "all your favourite properties in one place",
        ]
    };
    this.messenger.Send({
      messageType: MESSAGE_TYPES.SHOW_LOGIN,
      messageData: loginMessageData
    });
  }

  onEnquiriesClick(){
    this.closeMenu();

    if (this.isLoggedIn){
      this.router.navigate(['/enquiries']);
      return;
    }

    var loginMessageData: ShowLoginMessageData;
    this.applicationStateService.StorePostLoginReturnUrl("/favourites");
    loginMessageData = {
        showOverlay: true,
        isRegistration: true,
        explicitRedirectUrl: "/favourites",
        registerText: [
            "Placebuzz has much more to offer",
            "Sign up to",
            "keep",
            "all your favourite properties in one place",
        ]
    };
    this.messenger.Send({
      messageType: MESSAGE_TYPES.SHOW_LOGIN,
      messageData: loginMessageData
    });
  }

  onLogoutClick() {
    this.closeMenu();
    this.applicationStateService.LogoutAndClearMe();
    this.historyService.clearHistory();
    this.router.navigate(["/"]);
  }

  private OnMeStateChanged(me: Me) {
    if (me) {
        if (me.email) {
            this.isLoggedIn = true;
            if (me.fullName) {
                this.initial = me.fullName[0];
            }
            else {
                this.initial = me.email[0];
            }
        }
    }
    else {
        this.isLoggedIn = false;
    }
}


}

