import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'rating-stars',
    imports: [
        NgIf
    ],
    template: `
    <div class="rating-container" *ngIf="rating>0">
      <div class="star-rating" style="--rating: {{rating}};"></div>
      <span class="score">{{ratingText}}</span>
    </div>
  `,
    styleUrl: './rating.stars.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingStarsComponent {
  
  @Input() rating: number;
  @Input() ratingText: string;
  
}
