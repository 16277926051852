import { NgIf, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PropertyDetails } from '../property-details-page/model';
import { ElapsedTimeDisplay } from 'src/app/infrastructure/date.helpers';
import { Link } from 'src/app/infrastructure/model/link';
import { ActionAndContext } from 'src/app/infrastructure/actionAndContext';
import { LatitudeLongitude } from 'src/app/services/search.module/model/geo';
import { SponsoredListing } from 'src/app/services/campaigns.module/model';
import { Subscription } from 'rxjs';
import { Router, RouterLink } from '@angular/router';
import { SIMILAR_PROPERTIES_SUFFIX, SPONSORED_PROPERTIES_SUFFIX } from '../../property-search/search-page/model';
import { MediaPipe } from '../../../pipes/media.server.pipe';
import { SafeUrlPipe } from '../../../pipes/safe.url.pipe';
import { MortgageCalculatorComponent } from '../mortgage-calculator/mortgage-calculator.component';
import { PropertyCarouselComponent } from '../../../components/property-carousel/property-carousel.component';
import { PropertyMapComponent } from '../../../components/property-map/property-map.component';
import { IntersectionObserverDirective } from '../../../directives/interesection-observer.directive';
import { OpenBrowserIfAppDirective } from '../../../directives/open-browser-if-app';
import { ActionTrackingDirective } from '../../../directives/action-tracking.directive';
import { PbButtonComponent } from '../../../controls/pb-button/pb-button.component';
import { ImageCarouselComponent } from '../../../components/image-carousel/image-carousel.component';
import { PbImageComponent } from '../../../controls/pb-image/pb-image.component';
import { FavouriteSwitchComponent } from 'src/app/controls/favourite-switch/favourite-switch.component';
import { PropertyFeaturesComponent } from '../../../components/property-features/property-features.component';
import { LinkbuttonComponent } from '../../../controls/link-button/linkbutton.component';
import { PbCarouselComponent } from '../../../controls/pb-carousel/pb-carousel.component';
import { environment } from 'src/environments/environment';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';
import { ApplicationStateService } from 'src/app/services/application.state.module/application.state.service';
import { TrackingService } from 'src/app/services/tracking.module/tracking.service';
import { PropertyDetailsService } from 'src/app/services/property.details.module/property.details.service';
import { SocialSharingComponent } from 'src/app/components/social-sharing/social-sharing.component';
import { ISubscriber } from 'src/app/services/messenger.module/model/model';
import { MESSAGE_TYPES } from 'src/app/infrastructure/message.types';
import { Messenger } from 'src/app/services/messenger.module/messenger';
import { ShowTextPopupMessageData } from 'src/app/infrastructure/message.data.types';
import { AffiliateComponent } from 'src/app/components/affiliate/affiliate.component';
import { AdUnitComponent } from 'src/app/components/ad-unit/ad-unit.component';

@Component({
    selector: 'property-details',
    templateUrl: './property-details.component.html',
    styleUrls: ['./property-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        PbCarouselComponent,
        NgIf,
        LinkbuttonComponent,
        PropertyFeaturesComponent,
        FavouriteSwitchComponent,
        RouterLink,
        PbImageComponent,
        ImageCarouselComponent,
        PbButtonComponent,
        NgFor,
        ActionTrackingDirective,
        OpenBrowserIfAppDirective,
        IntersectionObserverDirective,
        PropertyMapComponent,
        PropertyCarouselComponent,
        MortgageCalculatorComponent,
        SafeUrlPipe,
        SocialSharingComponent,
        MediaPipe,
        AffiliateComponent,
        AdUnitComponent
    ]
})
export class PropertyDetailsComponent implements OnInit, OnDestroy {
 
  constructor(
    private platformService: PlatformHelpersService,
    private applicationStateService: ApplicationStateService,
    private propertyDetailsService: PropertyDetailsService,
    private trackingService: TrackingService,
    private cdr: ChangeDetectorRef,
    private messenger: Messenger,
    private router:Router
    
  ) {
    this.mediaServerUrl = environment.mediaServerUrl;
  }

  private subscription: Subscription;
  propertyDetails: PropertyDetails;
  showAds: boolean = true;
  showTestAds: boolean = true;
  isFavourite: boolean = false;
  mediaServerUrl: string;
  isDescTruncated: boolean = false;
  showMap: boolean = false;
  showStreetview:boolean = false;
  imageDocuments: Array<Link> = new Array<Link>();
  documents: Array<string> = new Array<string>();
  downloadableDocuments: Array<Link> = new Array<Link>();
  private _latLng: LatitudeLongitude;
  listerLogoUrl: string;
  sponsoredListings: Array<SponsoredListing>;


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.messenger.Unsubscribe(this.meStateChangedSubscriber);
    this.messenger.Unsubscribe(this.favouritesUpdatedSubscriber);
  }
  ngOnInit(): void {

    if (this.applicationStateService.isMobileApp){
      this.showAds = false;
    }

    this.showTestAds = this.platformService.showTestAds;
    
    this.subscription = this.propertyDetailsService.getPropertyDetailsObservable().subscribe(details => {
      this.propertyDetails = details;

      this.messenger.Subscribe(this.meStateChangedSubscriber);
      this.messenger.Subscribe(this.favouritesUpdatedSubscriber);
      this.InitializeIsFavourite();
      this.SetupDocuments();

      this.cdr.detectChanges();

    });

  }

  private favouritesUpdatedSubscriber: ISubscriber = {
    messageType: MESSAGE_TYPES.FAVOURITES_UPDATED,
    On: () => {
        this.InitializeIsFavourite();
    }
  }


  private meStateChangedSubscriber: ISubscriber = {
    messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
    On: () => {
      if (this.applicationStateService.me) {
          this.InitializeIsFavourite();
          this.cdr.detectChanges();
      }
    }
}

  get isForSale(): boolean {
    return this.propertyDetails?.listingTypeId === 2;
  }

  public get latLng(){
    if (this._latLng){
      return this._latLng;
    }

    this._latLng = new LatitudeLongitude(this.propertyDetails.latitude, this.propertyDetails.longitude);
    return this._latLng;
  }

  get elapsedTimeDisplay(): string {
    return ElapsedTimeDisplay(this.propertyDetails.firstPublishedDate);
  }

  onShowMap = new ActionAndContext<void>(() => {
    this.showMap = true;
    this.cdr.detectChanges();
  }, this, null);

  onShowStreetview = new ActionAndContext<void>(() => {
    this.showStreetview = true;
    this.cdr.detectChanges();
  }, this, null);

  private SetupDocuments() {
    this.imageDocuments = new Array<Link>();
    this.documents = new Array<string>();
    this.downloadableDocuments = new Array<Link>();
    if (this.propertyDetails.documents && this.propertyDetails.documents.length > 0) {
        for (var i = 0; i < this.propertyDetails.documents.length; i++) {
            var split = this.propertyDetails.documents[i].url.split(".");
            if (split.length > 1) {
                var extension = split[split.length - 1].toLowerCase().trim();
                if (extension === "bmp" ||
                    extension === "gif" ||
                    extension === "jpg" ||
                    extension === "jpeg" ||
                    extension === "png") {
                    this.imageDocuments.push(this.propertyDetails.documents[i]);
                    this.documents.push(this.propertyDetails.documents[i].url);
                }
                else if (extension) {
                    this.downloadableDocuments.push(this.propertyDetails.documents[i])
                }
            }
        }
        this.propertyDetails.documents = [...this.imageDocuments, ...this.downloadableDocuments];
    }
}

  onClickScroll(elementName){
    this.platformService.ScrollToElement(elementName);
  }
  
  onShareClicked(event:any){
      this.messenger.Send({
          messageType: MESSAGE_TYPES.SHARE_CLICKED,
          messageData: null
      });
  
  }

  private InitializeIsFavourite() {
    if (this.applicationStateService.me && this.applicationStateService.me.favourites) {
        this.isFavourite = !!this.applicationStateService.me.favourites.find(f => f.placeId === this.propertyDetails.listingId);
        this.cdr.detectChanges();
    }
}

  onAddToFavouritesClicked(value:boolean){
        this.isFavourite = value;
        if (value) {
            this.applicationStateService.AddToFavourites(this.propertyDetails.listingId).then(result => {
                this.isFavourite = result;
            });
        }
        else {
            this.applicationStateService.RemoveFromFavourites(this.propertyDetails.listingId).then(result => {
                this.isFavourite = result;
            });
        }
        this.cdr.detectChanges();
  }

  onTenancyInfoClicked(event:any){
    this.messenger.Send({
      messageType: MESSAGE_TYPES.SHOW_TEXT_POPUP,
      messageData: new ShowTextPopupMessageData("Tenant Fees Info", this.propertyDetails.tenancyInfo, "680px", "400px")
  })
  }

  onRequestDetailsClicked(event:any){
    this.router.navigate([this.propertyDetails.detailsUrl + "/contact-agent"], {queryParams: {mode: 'request_details_step1'}}).then(() => {
      this.platformService.ScrollToTop();
    });;
  }

  onContactAgentClicked(event:any){   
    this.router.navigate([this.propertyDetails.detailsUrl + "/contact-agent"]).then(() => {
      this.platformService.ScrollToTop();
    });;
  }

  onFullScreenClicked(){
    this.router.navigate([this.propertyDetails.detailsUrl + "/images"]).then(() => {
      this.platformService.ScrollToTop();
    });;
  }




OnSponsoredListingsObservedAction = new ActionAndContext<void>(() => {
    
  if (this.platformService.IsBrowserPlatform){
    this.propertyDetailsService.getSponsoredListings(this.propertyDetails.listingId).then(result => {
      this.sponsoredListings = result.sponsoredListings;
      if (this.sponsoredListings && this.sponsoredListings.length > 0) {
          for (var i = 0; i < this.sponsoredListings.length; i++) {
              this.sponsoredListings[i].eCommerceTracking.position = i + 1;
              this.sponsoredListings[i].eCommerceTracking.clientListForImpressions = this.trackingService.GetCurrentPageName() + SPONSORED_PROPERTIES_SUFFIX;
              this.sponsoredListings[i].eCommerceTracking.clientListForActions = this.trackingService.GetCurrentPageName() + SPONSORED_PROPERTIES_SUFFIX;
          }
          this.trackingService.TrackECommerceImpressions(this.sponsoredListings.map(l => l.eCommerceTracking));
          this.cdr.detectChanges();
      }
    });
  }
  
}, this, null);

OnSimilarListingsObservedAction = new ActionAndContext<void>(() => {

  if (this.platformService.IsBrowserPlatform){
    this.propertyDetailsService.getSimilarListings(this.propertyDetails.listingId.toString()).then(result => {
      this.propertyDetails.similarListings = result;
      if (this.propertyDetails.similarListings && this.propertyDetails.similarListings.length > 0) {
          for (var i = 0; i < this.propertyDetails.similarListings.length; i++) {
              this.propertyDetails.similarListings[i].eCommerceTracking.position = i + 1;
              this.propertyDetails.similarListings[i].eCommerceTracking.clientListForImpressions = this.trackingService.GetCurrentPageName() + SIMILAR_PROPERTIES_SUFFIX;
              this.propertyDetails.similarListings[i].eCommerceTracking.clientListForActions = this.trackingService.GetCurrentPageName() + SIMILAR_PROPERTIES_SUFFIX;
          }
          this.trackingService.TrackECommerceImpressions(this.propertyDetails.similarListings.map(l => l.eCommerceTracking));
          this.cdr.detectChanges();
      }
    });
  }
  
}, this, null);

 }
