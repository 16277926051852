import { Component, AfterViewInit, OnDestroy, Input, Inject, NgModule, ViewEncapsulation } from "@angular/core";
import { ActionAndContextWithInstanceId } from "src/app/infrastructure/actionAndContext";
import { LatitudeLongitude } from "src/app/services/search.module/model/geo";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { RegisterFunctionForWindowEventMessageData } from "src/app/infrastructure/message.data.types";
import { REGISTERABLE_WINDOW_EVENTS } from "src/app/infrastructure/registrable.window.events";
import { CommonModule, NgIf } from "@angular/common";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { LoggingService } from "src/app/services/logging.module/logging.service";


export class PropertyMapComponentModel {
    constructor(

    ) {

    }
}

@Component({
    selector: 'property-map',
    templateUrl: './property-map.component.html',
    styleUrls: ['./property-map.component.scss'],
    imports: [NgIf]
})
export class PropertyMapComponent implements AfterViewInit, OnDestroy {
    private _parameters: PropertyMapComponentModel;

    private windowSizeChangedAction = new ActionAndContextWithInstanceId(() => {
        this.ProcessMapSize();
    }, this, null, Math.random());

    //hasValue: boolean = false;
    markerLatLong: LatitudeLongitude;


    src: SafeResourceUrl;


    @Input() width: number;
    @Input() height: number = 300;
    @Input() isStreetView: boolean = false;
    @Input() mode:string = "EMBED";


    @Input() set latLng(value: LatitudeLongitude) {
      this.markerLatLong = value;
      var url = "";

      if (this.isStreetView) {
          url = "https://www.google.com/maps/embed/v1/streetview?key=AIzaSyDD0KOQpQtgPPb0MAYHSoT0xykBM7ISEAQ&location=" + this.markerLatLong.latitude + "," + this.markerLatLong.longitude;
      }
      else {
          url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDD0KOQpQtgPPb0MAYHSoT0xykBM7ISEAQ&q=" + this.markerLatLong.latitude + "," + this.markerLatLong.longitude + "&center=" + this.markerLatLong.latitude + "," + this.markerLatLong.longitude;
      }

      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      var ll = new Array<LatitudeLongitude>();
      ll.push(value);

      //this.ProcessMapSize();
    }

    get latLng(): LatitudeLongitude {
      return this.markerLatLong;
    }


    get parameters(): PropertyMapComponentModel {
        return this._parameters;
    }

    constructor(
        private messenger: Messenger,
        private platformService: PlatformHelpersService,
        protected loggingService: LoggingService,
        private sanitizer: DomSanitizer) {

        this.messenger.Send({
            messageType: MESSAGE_TYPES.REGISTER_TO_WINDOW_EVENTS,
            messageData: new RegisterFunctionForWindowEventMessageData(this.windowSizeChangedAction, REGISTERABLE_WINDOW_EVENTS.SIZE, true)
        });
    }

    ngAfterViewInit(): void {
      this.ProcessMapSize();
    }

    ngOnDestroy(): void {
        this.messenger.Send({
            messageType: MESSAGE_TYPES.REGISTER_TO_WINDOW_EVENTS,
            messageData: new RegisterFunctionForWindowEventMessageData(this.windowSizeChangedAction, REGISTERABLE_WINDOW_EVENTS.SIZE, false)
        });
    }

    private ProcessMapSize(): void {
      if(this.platformService.IsBrowserPlatform){
        if (this.platformService.Window.innerWidth < 768) {
            this.height = 300;
        }
        else{
            this.height = 500;
        }
      }
    }

}


