<div class="navbar">

    <a *ngIf="this.isMobileApp && this.hasBackStack" (click)="onBackClicked()" class="app-back-button">
      <pb-icon color="#ffffff" size="36" name="chevronleft" ></pb-icon>
    </a>

     <div class="hamburger-container">
      <button class="hamburger" (click)="toggleMenu()" [class.open]="isMenuOpen">
      ☰
      </button>
    </div>
    
     <div>
      <a routerLink="/" class="logo"></a>
     </div>

    <ul class="nav-links" [class.active]="isMenuOpen">
      <li><a routerLink="/property-for-sale" routerLinkActive="active" (click)="closeMenu()" class="menu-item">Buy</a></li>
      <li><a routerLink="/property-for-rent" routerLinkActive="active" (click)="closeMenu()" class="menu-item">Rent</a></li>
      <li><a routerLink="/area-guides" routerLinkActive="active" (click)="closeMenu()" class="menu-item">Area Guides</a></li>
      <li><a routerLink="/searches" routerLinkActive="active" (click)="closeMenu()" class="menu-item">Searches</a></li>
      <li><a (click)="onFavouritesClick()" class="menu-item">Favourites</a></li>
      <li><a (click)="onEnquiriesClick()" class="menu-item">Enquiries</a></li>

      <li class="more-menu">
        <span (click)="toggleMoreMenu()" class="menu-item">More</span>
        <ul class="dropdown" [class.show]="isMoreMenuOpen">
          <li><a routerLink="/property-advice" routerLinkActive="active" (click)="closeMoreMenu()" class="menu-item">Property advice</a></li>
          <li><a routerLink="/blog" routerLinkActive="active" (click)="closeMoreMenu()" class="menu-item">Blog</a></li>
        </ul>
      </li>
    </ul>
  
    
    <div class="icons">
      <a routerLink="/search" routerLinkActive="active" (click)="closeMenu()"><pb-icon size="32" color="#ffffff" name="search"></pb-icon></a>
      
      <a id="account-button" (click)="toggleProfileMenu()">
        <pb-icon id="loggged-out-icon" *ngIf = "!isLoggedIn" size="32" color="#ffffff" name="user"></pb-icon>
        <div id="logged-in-icon" *ngIf="isLoggedIn" class="circle">{{initial}}</div>
      </a>
      
      <div class="profile-menu" [class.show]="isProfileMenuOpen">
        <ul class="dropdown" >
          <li *ngIf="isLoggedIn"><a routerLink="/account" routerLinkActive="active" (click)="closeProfileMenu()" class="menu-item">My account</a></li>
          <li *ngIf="isLoggedIn"><a (click)="onLogoutClick()" class="menu-item">Logout</a></li>

          <li *ngIf="!isLoggedIn"><a (click)="onRegisterClick()" class="menu-item">Sign up</a></li>
          <li *ngIf="!isLoggedIn"><a (click)="onLoginClick()" class="menu-item">Log in</a></li>
        </ul>
      </div>
    </div>
  </div>