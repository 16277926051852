import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'pb-footer',
    templateUrl: './pb-footer.component.html',
    styleUrls: ['./pb-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [RouterLink]
})
export class PbFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentYear: number = new Date().getFullYear();

}

