<div #placebuzzRoot class='layout-root'>
    <pb-navbar *ngIf="this.showNavBar"></pb-navbar>

    <div class="app-main-wrapper">
      <div class="mobile-scrolling-placeholder"></div>
      <app-main></app-main>
    </div>
    <div class="app-footer-wrapper" *ngIf="showFooter">
      <pb-footer></pb-footer>
    </div>
  </div>

  <confirm-email-banner></confirm-email-banner>
  <overlay-container></overlay-container>
  <error-overlay-container></error-overlay-container>
  <in-app-toast></in-app-toast>
