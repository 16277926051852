import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

import { Loader } from "@googlemaps/js-api-loader";
import { ElapsedTimeDisplay } from "src/app/infrastructure/date.helpers";
import { PlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { PropertyListing } from "src/app/infrastructure/model/propertyListing";
import { CopyObject } from "src/app/infrastructure/object.helpers";
import { LatitudeLongitude } from "src/app/services/search.module/model/geo";
import { PropertyListingsResult } from "src/app/services/search.module/model/propertyListingsResult";
import { SafeUrlPipe } from "../../../pipes/safe.url.pipe";
import { PbImageComponent } from "../../../controls/pb-image/pb-image.component";
import { NavAnchorDirective } from "../../../directives/nav-anchor.directive";
import { PbIconComponent } from "../../../controls/pb-icon/pb-icon.component";
import { NgIf } from "@angular/common";
import { PbButtonComponent } from "../../../controls/pb-button/pb-button.component";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";

@Component({
    selector: "search-map",
    templateUrl: "./search-map.component.html",
    styleUrls: ["./search-map.component.scss"],
    encapsulation: ViewEncapsulation.None,
    imports: [PbButtonComponent, NgIf, PbIconComponent, NavAnchorDirective, PbImageComponent, SafeUrlPipe]
})
export class SearchMapComponent {

  constructor(
    private platformHelpersService: PlatformHelpersService
  ) {}

  @Input() isSearching: boolean = false;
  private _placeSearchCriteria: PlaceSearchCriteria;
  
  @Input() set placeSearchCriteria(value: PlaceSearchCriteria) {
    this._placeSearchCriteria = value;
    if (this.platformHelpersService.IsBrowserPlatform){
      this.LoadMap();
    }
  }

  get placeSearchCriteria(): PlaceSearchCriteria {
    return this._placeSearchCriteria;
  }
  @Input() propertyListings: PropertyListingsResult;

  @Output() searchClicked = new EventEmitter<any>();
  @Output() listClicked= new EventEmitter<any>();

  // @ViewChild("map") set mapElement(map: any) {
  //   setTimeout(() => {
  //     if (!this.map) {
        
  //     }
  //   }, 0);
  // }




  bounds:google.maps.LatLngBounds;

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    // Trigger the map resize when the window is resized
    google.maps.event.trigger(this.map, 'resize');
    this.map.fitBounds(this.bounds);
  }

  @HostListener('window:resize', ['$event'])
  onWindowHeightChange(event: Event) {
    // Trigger the map resize when the window's height is changed
    google.maps.event.trigger(this.map, 'resize');
    this.map.fitBounds(this.bounds);
  }

  loader = new Loader({
    apiKey: "AIzaSyDD0KOQpQtgPPb0MAYHSoT0xykBM7ISEAQ",
    version: "weekly",
    libraries: ["geometry", "places"],
  });

  selectedPropertyListing: PropertyListing;
  isPropertyDetailsVisible: boolean = false;
  map: any;
  private markers = [];
  private metersToMiles = 0.000621371;

  

  onClickedClosePropertyDetails() {
    this.selectedPropertyListing = null;
    this.isPropertyDetailsVisible = false;
  }

  onClickedList() {
    this.listClicked.emit();
  }

  getListingUrl(p: PropertyListing): string {
    if (p.isSponsored && p.campaignId > 0) {
      return p.detailsUrl + "?campaignId=" + p.campaignId;
    }

    return p.detailsUrl;
  }

  get elapsedTimeDisplay(): string {
    return ElapsedTimeDisplay(this.selectedPropertyListing.firstPublishedDate);
  }

  LoadMap(setbounds: boolean = true) {

    if (!this.placeSearchCriteria){
      return;
    }



    this.loader
      .load()
      .then((google) => {
        var lat = Number(this.placeSearchCriteria.latitude);
        var lng = Number(this.placeSearchCriteria.longitude);

        var centrePoint = new google.maps.LatLng(lat, lng);

        this.ClearMarkers();

        if (!this.map) {
          const mapProperties = {
            center: centrePoint,
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            zoomControl: true,
            scaleControl: true,
            fullscreenControl: false,
            clickableIcons: false,
          };

          this.map = new google.maps.Map(
            document.getElementById("map"),
            mapProperties
          );
        } else {
          this.map.setCenter(centrePoint);
        }

        this.DrawMarkers(setbounds);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  DrawMarkers(setbounds: boolean = true) {

    if (!this.propertyListings){
      return;
    }

    this.bounds = new google.maps.LatLngBounds();
    this.markers = [];

    this.propertyListings.listings.forEach((property) => {
      var mapSearchMarker = new MapSearchMarker(
        parseFloat(property.latitude.toString()),
        parseFloat(property.longitude.toString()),
        property.features[0].iconUrl,
        property.formattedPrice,
        property.placeId.toString(),
        this
      );
      this.CreateCustomMarker(this.map, mapSearchMarker);
      this.bounds.extend(
        new google.maps.LatLng(property.latitude, property.longitude)
      );
    });

    if (setbounds) {
      this.map.fitBounds(this.bounds);
    }
  }

  OnClickedSearchHere() {

    this.ClearMarkers();

    var newCenter = this.map.getCenter();
    let radius = this.CalculateSearchRadius();

    this.placeSearchCriteria.page=1;
    this.placeSearchCriteria.searchArea = null;
    this.placeSearchCriteria.locationName = null;
    this.placeSearchCriteria.radius = radius;
    this.placeSearchCriteria.latitude = newCenter.lat();
    this.placeSearchCriteria.longitude = newCenter.lng();
    this.placeSearchCriteria.type="CentrePoint";

    this.placeSearchCriteria.radius = this.CalculateSearchRadius();

    this.ClearMarkers();
    let searchCriteria = CopyObject(this.placeSearchCriteria);
    delete searchCriteria.localityId;

    searchCriteria.type = "CentrePoint";

    this.searchClicked.emit(searchCriteria);
}

  private CreateCustomMarker(mapInstance: any, marker: MapSearchMarker) {
    let CustomMarker = class extends google.maps.OverlayView {
      private div;
      private width = 44;
      private height = 44;

      click: Function;

      constructor(
        private mapInstance: any, 
        private marker: MapSearchMarker) {
        super();
        this.setMap(mapInstance);
      }

      override draw() {
        var overlayProjection = this.getProjection();
        var pointInDiv = overlayProjection.fromLatLngToDivPixel(
          new google.maps.LatLng(this.marker.latitude, this.marker.longitude)
        );
        this.div.style.left = pointInDiv.x - this.width / 2 + "px";
        this.div.style.top = pointInDiv.y - this.height / 2 + "px";
      }

      override onAdd() {
        this.div = document.createElement("div");
        let priceDiv = document.createElement("div");
        let priceSubDiv = document.createElement("div");
        let iconDiv = document.createElement("div");
        let priceText = document.createTextNode(this.marker.price);
        
        
        
        this.div.classList.add("pb-marker");
        priceDiv.classList.add("price");

        priceSubDiv.style.content = "";
        priceSubDiv.classList.add("pb-marker-triangle");
        
        priceDiv.appendChild(priceText);

        var iconSplit = this.marker.iconUrl.split("/");
        var iconFile = iconSplit[iconSplit.length - 1].split(".")[0];
        iconDiv.classList.add("mapicon");
        iconDiv.classList.add("mapicon-" + iconFile);
        this.div.appendChild(priceDiv);
        this.div.appendChild(priceSubDiv);
        this.div.appendChild(iconDiv);
        this.div.style.position = "absolute";

        var self = this;

        google.maps.event.addDomListener(this.div, "click", function () {
          self.click(self.marker);
        });

        var panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.div);
      }

      override onRemove() {
        try {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        } catch {}
      }

      removeFromMap() {
        this.click = null;
        this.setMap(null);
      }
    };

    let customMarker = new CustomMarker(mapInstance, marker);
    customMarker.click = this.OnCustomMarkerClicked.bind(marker.context);
    this.markers.push(customMarker);
  }

  

  private CalculateSearchRadius(inMiles: boolean = true): number {
    var newBounds = this.map.getBounds();

    var newCenter = this.map.getCenter();

    let south = newBounds.getSouthWest().lat();
    let west = newBounds.getSouthWest().lng();
    let north = newBounds.getNorthEast().lat();
    let east = newBounds.getNorthEast().lng();
    let latDiff = north - south;
    let lonDiff = east - west;

    let minPoint: google.maps.LatLng;

    if (latDiff < lonDiff) {
      minPoint = new google.maps.LatLng(north, west + lonDiff / 2);
    } else {
      minPoint = new google.maps.LatLng(south + latDiff / 2, east);
    }

    var distance = this.CalculateDistance(newCenter, minPoint);
    if (inMiles === true) {
      distance = distance * this.metersToMiles;
    }

    if (distance > 5) {
      distance = 5;
    }
    return distance;
  }

  private CalculateDistance(
    point1: google.maps.LatLng,
    point2: google.maps.LatLng
  ): number {
    return google.maps.geometry.spherical.computeDistanceBetween(
      point1,
      point2
    );
  }

  private ClearMarkers() {
    for (var r = 0; r < this.markers.length; r++) {
      this.markers[r].removeFromMap();
    }
  }

  private OnCustomMarkerClicked(marker: MapSearchMarker) {
    this.selectedPropertyListing = this.propertyListings.listings.find(
      (l) => l.placeId === parseInt(marker.placeId)
    );

    this.isPropertyDetailsVisible = true;
  }

 
}

export class MapSearchMarker extends LatitudeLongitude {
  constructor(
    latitude: number,
    longitude: number,
    public iconUrl: string,
    public price: string,
    public placeId: string,
    public context: any
  ) {
    super(latitude, longitude);
  }
}
