import { Component, EventEmitter, Input, Output } from '@angular/core';
import { lookupData } from 'src/app/services/initialization.module/Initialization.data';
import { PbButtonComponent } from '../../../controls/pb-button/pb-button.component';
import { PbDropdownComponent } from '../../../controls/pb-dropdown/pb-dropdown.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'results-header',
    templateUrl: './results-header.component.html',
    styleUrls: ['./results-header.component.scss'],
    imports: [NgIf, PbDropdownComponent, PbButtonComponent]
})
export class ResultsHeaderComponent {

  @Input() currentPage:number;
  @Input() firstPageNumber:number;
  @Input() lastPageNumber:number;
  @Input() exactMatchCount:number;
  @Input() extendedMatchCount:number;
  @Input() listingMode:string = "list";
  @Input() sortName:string;
  
  @Output() listingModeChanged = new EventEmitter<string>();
  @Output() sortChanged = new EventEmitter<string>();

  sortData = lookupData.sortBy;

  SelectListingMode(listingMode:string){
    this.listingMode = listingMode;
    this.listingModeChanged.emit(listingMode);
  }

  onSortChanged(){
    this.sortChanged.emit(this.sortName);
  }

}
