import { Component, Input, ViewEncapsulation, OnDestroy, AfterViewInit } from "@angular/core";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { NgIf, NgStyle } from "@angular/common";
import { LoggingService } from "src/app/services/logging.module/logging.service";
import { AdSlotDefinition } from "src/app/services/double.click.ads.module/model";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { DoubleClickAdsService } from "src/app/services/double.click.ads.module/double.click.ads.service";
import { IntersectionObserverDirective } from "src/app/directives/interesection-observer.directive";

@Component({
    selector: 'dcp-ad-v2',
    templateUrl: 'dcp-ad-v2.component.html',
    styleUrls: ['dcp-ad-v2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgStyle, IntersectionObserverDirective]
})

export class DcpV2AdComponent implements OnDestroy, AfterViewInit {
    constructor(
        private doubleClickAdsService: DoubleClickAdsService,
        private loggingService: LoggingService,
        private platformHelper:PlatformHelpersService
    ) { }
  ngAfterViewInit(): void {
      if (this.platformHelper.IsBrowserPlatform && this.doubleClickAd && this.doubleClickAd.mode=="sra" && this.platformHelper.adsProvider=="admanager"){
        this.loggingService.LogToDevConsole("   <dcp-ad>Calling Display Ad for " + this.doubleClickAd.slotName + "(" + this.doubleClickAd.slotId + ")" + " with observable margin " + this.observeMargin + ("</dcp-ad>"));
        let mySlot = this.doubleClickAdsService.DisplayAd(this.doubleClickAd.slotId);
        this.slot = mySlot;
        this.loaded = true;
        this.displayed = true;
      }
  }


    @Input() doubleClickAd: AdSlotDefinition;
    @Input() width: string;
    @Input() height: string;
    @Input() extraClasses: string;
    @Input() cacheBuster:string;

    private  slot: any;
    private loaded:boolean=false;
    private displayed:boolean=false;
    get widthPx() { return this.width + "px"; }
    get heightPx() { return this.height + "px"; }


    get observeMargin(){
      if (this.doubleClickAd && this.doubleClickAd.lazyloadMargin){
        return this.doubleClickAd.lazyloadMargin + "px";
      }
      return "0px";
    }

    get minHeightPx(){
      if (this.doubleClickAd && this.doubleClickAd.minHeight){
        return this.doubleClickAd.minHeight + "px";
      }
      return "";
    }


    getLargestHeight(doubleClickAd: AdSlotDefinition) {
      var sizes = doubleClickAd.size;
      var largestHeight = 0;
      for (var i = 0; i < sizes.length; i++) {
        if (+sizes[i].height > largestHeight) {
          largestHeight = +sizes[i].height;
        }
      }
      return largestHeight;
    }

    getLargestWidth(doubleClickAd: AdSlotDefinition) {
      var sizes = doubleClickAd.size;
      var largestWidth = 0;
      for (var i = 0; i < sizes.length; i++) {
        if (+sizes[i].width > largestWidth) {
          largestWidth = +sizes[i].width;
        }
      }
      return largestWidth;
    }

    OnObservedAction = new ActionAndContext(() => {

      if (!this.doubleClickAd){
        return;
      }

      var self = this;

      if (!this.loaded && !this.displayed){

        if (this.platformHelper.adsProvider=="admanager"){
          this.loggingService.LogToDevConsole("   <dcp-ad> Lazy Loading Ad for " + this.doubleClickAd.slotName + "(" + this.doubleClickAd.slotId + ")" + " with observable margin " + this.observeMargin + ("</dcp-ad>"));
          let mySlot = this.doubleClickAdsService.RegisterSlot(this.doubleClickAd.slotId);
          this.slot = mySlot;
        }

        if (this.platformHelper.adsProvider=="tude"){
          this.platformHelper.Window.tude = this.platformHelper.Window.tude  || { cmd: [] };
          
          var adDivId = this.doubleClickAd.slotId;
          var adBaseDivId = this.doubleClickAd.slotName.replace('/64165495/', 'pb-slot-');

          this.loggingService.LogToDevConsole("   <dcp-ad> Lazy Loading TUDE Ad for " + adBaseDivId + "(" + adDivId + ")" + " with observable margin " + this.observeMargin + ("</dcp-ad>"));

          this.loaded = true;
          this.displayed = true;

          
          try{
            self.platformHelper.Window.tude.cmd.push(function() {
              self.platformHelper.Window.tude.refreshAdsViaDivMappings([
              {
                divId: adBaseDivId,
                baseDivId: adBaseDivId
              }
              ]);
            });
          }
          catch(err){
            this.loggingService.LogErrorToDevConsole("   <dcp-ad> Error Loading TUDE Ad for " + adBaseDivId + "(" + adDivId + ")" + err);
          }
        }

        
      }
    }, this, null);


    get tudeName(){
      try{
        return this.doubleClickAd.slotName.replace('/64165495/', 'pb-slot-');
      }
      catch{}

      return this.doubleClickAd.slotName;
    }

    ngOnDestroy():void{
      if (!this.doubleClickAd){
        return;
      }
      this.loggingService.LogToDevConsole("   <dcp-ad> Destroyed " + this.doubleClickAd.slotName + "(" + this.doubleClickAd.slotId + ")" + ("</dcp-ad>"));
    }
}






