import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RemoveSubscriptions } from 'src/app/infrastructure/object.helpers';
import { AdsService } from 'src/app/services/ads.service.module/ads.service';
import { BreakpointService } from 'src/app/services/breakpoint.module/breakpoint.service';
import { DoubleClickAdsService } from 'src/app/services/double.click.ads.module/double.click.ads.service';
import { LoggingService } from 'src/app/services/logging.module/logging.service';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';

@Component({
  template: '',
  standalone: true,
  providers: [
    AdsService,
    LoggingService,
    BreakpointService,
  ]
})


export class AdsBasePageComponent implements OnDestroy {
    constructor(
        protected adsService: AdsService,
        protected platformHelpersService: PlatformHelpersService,
        protected loggingService: LoggingService,
        protected breakpointService: BreakpointService,
        protected router: Router,
        protected doubleClickAdsService: DoubleClickAdsService,
    ) { 

        this.adssubscriptions.push(this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                if (this.platformHelpersService.adsProvider === 'admanager') {
                    this.loggingService.LogToDevConsole("<Ads-Base-Page>Clearing ads</Ads-Base-Page>");  
                    this.doubleClickAdsService.ClearAds();
                }
                
        }));
    }

    private adssubscriptions: Array<Subscription> = new Array<Subscription>();
    protected adsPage: string = "";

    ngOnDestroy(): void {
        this.loggingService.LogToDevConsole("<Ads-Base-Page>ngOnDestroy</Ads-Base-Page>");
        RemoveSubscriptions(this.adssubscriptions);
    }

    

    
    

}