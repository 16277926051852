import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AverageAskingPrice } from 'src/app/services/search.module/model/averageAskingPrice';

@Component({
    selector: 'average-price-box',
    imports: [NgIf
    ],
    template: `<div *ngIf="averagePrices" class="average-prices">
              <h3 class="small">Average {{searchType}} price</h3>
              <p class="subtitle">{{searchArea}}</p>
              <div class="price-summary">
                  <p class="large-number">{{averagePrice}}</p>
                  <p *ngIf="comparePeriod" class="compare-period"><span class="{{increaseDecreasePercentage>0?'plus':'minus'}}">{{increaseDecreasePercentage.toFixed(1)}}%</span> {{comparePeriod}}</p>
              </div>
              <p class="summary">{{averagePrice}} is based on the prices of all available properties in {{searchArea}}.<span *ngIf="comparePeriod">This price has {{increaseDecreasePercentage>0?'increased':'decreased'}} by {{increaseDecreasePercentage.toFixed(1)}}% when comparing {{comparePeriod}}</span></p>
            </div>`,
    styleUrl: './average-price-box.component.scss'
})
export class AveragePriceBoxComponent { 

  _averagePrices: AverageAskingPrice[];
  @Input() searchType:string;
  @Input() searchArea: string;

  @Input() set averagePrices(value: AverageAskingPrice[]) {
    if (!value)
      return;

    this._averagePrices = value;

    var averagePrice = value[value.length - 1];
    var previousAskingPrice = value[value.length - 2];

    if (previousAskingPrice && averagePrice) {
     this.increaseDecreasePercentage  = ((averagePrice.price - previousAskingPrice.price ) / averagePrice.price) * 100;
    }
    this.averagePrice = averagePrice.priceFormatted;
    
    this.comparePeriod = "";
    if (averagePrice && previousAskingPrice){
      this.comparePeriod = averagePrice.abbreviatedMonth + " vs " + previousAskingPrice.abbreviatedMonth;
    }

    
  }

  get averagePrices(): AverageAskingPrice[] {
    return this._averagePrices;
  }

  averagePrice: string;
  increaseDecreasePercentage: number = 0;
  comparePeriod:string;

}
