import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';
import { DcpV2AdComponent } from '../dcp-ad-v2/dcp-ad-v2.component';
import { DoubleClickAdsService } from 'src/app/services/double.click.ads.module/double.click.ads.service';
import { ApplicationStateService } from 'src/app/services/application.state.module/application.state.service';
import { BreakpointService } from 'src/app/services/breakpoint.module/breakpoint.service';

@Component({
    selector: 'ad-unit',
    imports: [
        NgIf, DcpV2AdComponent
    ],
    template: `
    <div class="ad-container">
      <dcp-ad-v2 *ngIf="!this.showTestAd && this.adUnitType==='dcp' && showAd" [doubleClickAd]="this.getAd(this.page, this.adPosition)"></dcp-ad-v2>
      <div class="test-ad" *ngIf="this.showTestAd && showAd">{{this.adPosition}}</div>
    </div>
  `,
    styles: `
    :host {
      display: block;
    }
    
    .ad-container > div{
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .test-ad{
      background-color: #77cc22;
      height: 100px;
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdUnitComponent implements OnDestroy {
  constructor(
    private platformHelpersService: PlatformHelpersService,
    private applicationStateService: ApplicationStateService,
    private doubleClickAdsService: DoubleClickAdsService,
    private breakpointService: BreakpointService
  ) { }
  ngOnDestroy(): void {
  }
  @Input() page: string = "";
  @Input() adPosition: string = "";
  @Input() showOnMobile: boolean = true;
  @Input() showOnDesktop: boolean = true;

  get showAd(): boolean {
      if(this.applicationStateService.isMobileApp) {
        return false
      };
      
      var isMobile =  this.breakpointService.getBreakpoint() === 'mobile' || this.breakpointService.getBreakpoint() === 'tablet' ? "mobile" : "desktop";
      if (isMobile === "mobile" && this.showOnMobile === false) return false;
      if (isMobile === "desktop" && this.showOnDesktop === false) return false;

      return true;
  }


  get showTestAd(): boolean {
    return this.platformHelpersService.showTestAds;
  }

  get adUnitType():string{

    if (this.platformHelpersService.adsProvider === 'fuse') {
      return 'fuse';
    }
    else if (this.platformHelpersService.adsProvider === 'admanager' || this.platformHelpersService.adsProvider === 'tude') {
      return 'dcp';
    }

    return '';
  }

  getAd(page: string, adPosition: string) {
    return this.doubleClickAdsService.GetAdSlot(page, adPosition);
  }

 }
