import { OnInit, OnDestroy, Component, ComponentFactoryResolver, Injector, Inject, NgModule } from "@angular/core";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ErrorMessageData } from "src/app/infrastructure/message.data.types";
import { ErrorComponent } from "../error/error.component";
import { PopupComponent } from "src/app/controls/popup/popup.component";
import { DynamicComponentContainerComponentBase, IDynamicComponentParameters } from "../component.base.classes/dynamic.component.container.component.base";
import { NgIf } from "@angular/common";
import { Messenger } from "src/app/services/messenger.module/messenger";


@Component({
    selector: 'error-overlay-container',
    templateUrl: 'error-overlay-container.component.html',
    imports: [NgIf]
})

export class ErrorOverlayContainerComponent extends DynamicComponentContainerComponentBase implements OnInit, OnDestroy {
    isVisible: boolean = false;

    private errorSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.ERROR,
        On: (data: ErrorMessageData) => {
            var popupDynamicComponentParameters: IDynamicComponentParameters = {
                componentType: null,
                dynamicComponentResolver: (r: ComponentFactoryResolver) => {
                    return r.resolveComponentFactory(ErrorComponent)
                },
                componentParameters: data
            };

            if (data.errorType === null) {
                this.isVisible = false;
                if (this.currentComponent) {
                    this.CleanUp();
                }
            }
            else {
                if (this.isVisible === false) {
                    this.ShowPopup(popupDynamicComponentParameters);
                }
            }
        }
    }

    private navigatedSubscriber: ISubscriber = {
        messageType: MESSAGE_TYPES.NAVIGATED,
        On: () => {
            this.isVisible = false;
            if (this.currentComponent) {
                this.CleanUp();
            }
        }
    }

    constructor(
        resolver: ComponentFactoryResolver,
        injector: Injector,
        private messenger: Messenger) {
        super(resolver, injector);
        this.messenger.Subscribe(this.errorSubscriber);
        this.messenger.Subscribe(this.navigatedSubscriber);
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.errorSubscriber);
        this.messenger.Unsubscribe(this.navigatedSubscriber);
    }

    private ShowPopup(popupDynamicComponentParameters: IDynamicComponentParameters) {
        this.isVisible = true;
        this.dynamicComponentParameters = {
            componentType: null,
            componentParameters: null,
            dynamicComponentResolver: (resolver: ComponentFactoryResolver) => {
                return resolver.resolveComponentFactory(PopupComponent)
            }
        };
        var popupComponent = this.currentComponent.instance as PopupComponent;
        popupComponent.dynamicComponentParameters = popupDynamicComponentParameters;
        popupComponent.canUserClose = false;
        popupComponent.Size();
    }
}


