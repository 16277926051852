import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyListingsResult } from 'src/app/services/search.module/model/propertyListingsResult';
import { SafeUrlPipe } from '../../../pipes/safe.url.pipe';
import { LinkbuttonComponent } from '../../../controls/link-button/linkbutton.component';
import { RelatedSearchesComponent } from '../../../components/related-searches/related-searches.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { PropertyListingComponent } from 'src/app/components/property-listing/property-listing.component';
import { AffiliateComponent } from 'src/app/components/affiliate/affiliate.component';
import { PbButtonComponent } from '../../../controls/pb-button/pb-button.component';
import { NgIf, NgFor } from '@angular/common';
import { BreakpointService } from 'src/app/services/breakpoint.module/breakpoint.service';
import { AverageAskingPrice } from 'src/app/services/search.module/model/averageAskingPrice';
import { AreaGuideBoxComponent } from '../area-guide-box/area-guide-box.component';
import { AveragePriceBoxComponent } from '../average-price-box/average-price-box.component';
import { PlatformHelpersService } from 'src/app/services/platform.helpers.module/platform.helpers.service';
import { PlacebuzzSearchService } from 'src/app/services/search.module/placebuzz.search.service';
import { IntersectionObserverDirective } from 'src/app/directives/interesection-observer.directive';
import { ActionAndContext } from 'src/app/infrastructure/actionAndContext';
import { AdUnitComponent } from 'src/app/components/ad-unit/ad-unit.component';

@Component({
    selector: 'search-list',
    templateUrl: './search-list.component.html',
    styleUrls: ['./search-list.component.scss'],
    imports: [NgIf, PbButtonComponent, NgFor, AffiliateComponent, PropertyListingComponent,
        PaginationComponent, BreadcrumbComponent, RelatedSearchesComponent, LinkbuttonComponent, AdUnitComponent,
        AreaGuideBoxComponent, AveragePriceBoxComponent, IntersectionObserverDirective]
})
export class SearchListComponent implements OnInit {

  constructor(
    private breakpointService: BreakpointService,
    private platformService: PlatformHelpersService,
    private placebuzzSearchService: PlacebuzzSearchService
  ) { }

  _propertyListings: PropertyListingsResult;  
  averagePrices : AverageAskingPrice[];

  recommendationsStart: number = -1;

  @Output() onAlertsSaveClick = new EventEmitter();
  @Output() pageChanged = new EventEmitter<any>();
  @Output() newPageClicked = new EventEmitter<any>();
  @Output() saveClicked = new EventEmitter<any>();

  @Input() isSearchSaved:boolean = false;

  ngOnInit(): void {
    this.breakpointService.getBreakpoint();
  }

  getBreakpoint(): string {
    return this.breakpointService.getBreakpoint();
  }


  navigateToPage(url: any){
    this.newPageClicked.emit(url);
  }


    onPageChanged(page: any) {
      this.pageChanged.emit(page);
    }

  // @Input() set averageAskingPrices(value: AverageAskingPrice[]){
  //   if (!value) return;

  //   this.averagePrices = value;
  // }

  // get averageAskingPrices(): AverageAskingPrice[]{
  //   return this.averagePrices;
  // }

    averageAskingPrices: AverageAskingPrice[];



  @Input() set propertyListings(value: PropertyListingsResult) {
    if (!value) return;

    this._propertyListings = value;
    
    var firstRecommendation = this.propertyListings.listings.find(l => l.listingAdType !== "Featured" && l.isRecommendation === true);
    if (firstRecommendation) {
        var recommendationPosition = this.propertyListings.listings.indexOf(firstRecommendation);
        if (recommendationPosition > 0){
            this.recommendationsStart = recommendationPosition;
        }
        else{
            if (this.propertyListings.pagination.currentPage > 1){
                this.recommendationsStart = -1;
            }
            else{
                this.recommendationsStart = recommendationPosition;
            }
        }
    }
    else
    {
            this.recommendationsStart = -1;
    }
  }

  get propertyListings(): PropertyListingsResult {
    return this._propertyListings;
  }

  OnTurnOnAlertsClicked(){
    this.onAlertsSaveClick.emit();
    
  }


  onAveragePricesShown = new ActionAndContext<void>(() => {
    if (this.platformService.IsBrowserPlatform) {

      if (this.propertyListings && this.propertyListings.placeSearchCriteria 
        && this.propertyListings.placeSearchCriteria.page == 1
        && (this.propertyListings.placeSearchCriteria.maxNumberOfBedroomsId==null && this.propertyListings.placeSearchCriteria.minNumberOfBedroomsId==null)) {
        
          this.placebuzzSearchService.PerformAveragePricesQuery(this.propertyListings.placeSearchCriteria.searchTypeId == 1 ? "property-to-rent" : "property-for-sale", this.propertyListings.placeSearchCriteria.localityId,
          null, this).then(result => {
              if (result.averageAskingPrices && result.averageAskingPrices.length > 0) {
                  this.averagePrices = result.averageAskingPrices;
              }
              else {
                  this.averagePrices = null;
              }
          });
    }
    else{
        this.averagePrices = null;
    }
    }
  }, this, null);


  OnSaveSearchClicked(){
    this.saveClicked.emit();
  }




}
