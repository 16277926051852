
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ShowLoginMessageData, ShowInAppToastMessageData } from "src/app/infrastructure/message.data.types";
import { CONTACT_AGENT_MODES, ContactAgentMutationResult, ContactAgentInput } from "./model";
import { GqlError } from "src/app/services/graphql.module/model";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { QUERY_HYDRATION_KEYS } from "src/app/infrastructure/query.hydration.keys";
import { GqlQueryBuilder } from "src/app/services/graphql.module/graphql.query.builder";
import { ContactAgentMutation } from "src/app/graphql/mutations/ContactAgentMutation";
import { StringKeyValuePair } from "src/app/infrastructure/types";
import { CopyObject } from "src/app/infrastructure/object.helpers";
import { Subscription, combineLatest } from "rxjs";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { PropertyDetails } from "src/app/pages/property-details/property-details-page/model";
import { ISubscriber } from "src/app/services/messenger.module/model/model";
import { ActionAndContext } from "src/app/infrastructure/actionAndContext";
import { SIMILAR_PROPERTIES_SUFFIX } from "src/app/pages/property-search/search-page/model";
import { EnquiryItem } from "src/app/components/enquiry.item.component/model";
import {requestDetailsForSale, requestDetailsForRent} from "./contact-agent-data";
import { ActionTrackingDirective } from "../../../directives/action-tracking.directive";
import { NavAnchorDirective } from "../../../directives/nav-anchor.directive";
import { PropertyCarouselComponent } from "../../../components/property-carousel/property-carousel.component";
import { IntersectionObserverDirective } from "../../../directives/interesection-observer.directive";
import { PbTextboxComponent } from "../../../controls/pb-textbox/pb-textbox.component";
import { ValidatingControlContainerComponent } from "../../../components/validating-control-container/validating-control-container.component";
import { PbButtonComponent } from "../../../controls/pb-button/pb-button.component";
import { CheckBoxComponent } from "../../../controls/check-box/check-box.component";
import { NgIf, NgFor, Location } from "@angular/common";
import { GraphQLService } from "src/app/services/graphql.module/graphql.service";
import { ApplicationStateService } from "src/app/services/application.state.module/application.state.service";
import { Messenger } from "src/app/services/messenger.module/messenger";
import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { PlatformHelpersService } from "src/app/services/platform.helpers.module/platform.helpers.service";
import { EnquiriesService } from "src/app/services/enquiries.service.module/enquiries.service";
import { PropertyDetailsService } from "src/app/services/property.details.module/property.details.service";
import { AuthorizationService } from "src/app/services/authorization.module/authorization.service";
import { PlacebuzzSearchService } from "src/app/services/search.module/placebuzz.search.service";
import { CreatePropertySimilarListingsQueryPart } from "src/app/graphql/queries/similar.listings.query";
import { HistoryService } from "src/app/services/history.service.module/history.service";
import { PbIconComponent } from "src/app/controls/pb-icon/pb-icon.component";

@Component({
    selector: 'contact-agent',
    templateUrl: './contact-agent.component.html',
    styleUrls: ['./contact-agent.component.scss'],
    imports: [NgIf, RouterLink, NgFor, CheckBoxComponent, PbButtonComponent, ValidatingControlContainerComponent, PbTextboxComponent, IntersectionObserverDirective, PropertyCarouselComponent, NavAnchorDirective, ActionTrackingDirective, PbIconComponent]
})

export class ContactAgentComponent implements OnInit, OnDestroy{

    private subscriptions: Array<Subscription> = new Array<Subscription>();
    
    name: string;
    email: string;
    telephone: string;
    postcode: string;
    message: string;
    photoUrl:string;


    isForSale: boolean;
    validationErrors: Array<GqlError>;
    mode: string;
    completed: boolean = false;
    requestDetailsSelections: Array<boolean> = new Array<boolean>();
    existingUserMessage: string;
    existingUserEmailOrPhone: string;
    requestDetailsList: Array<StringKeyValuePair>;
    propertyDetails: PropertyDetails;

    propertyId: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private gqlService: GraphQLService,
        private applicationStateService: ApplicationStateService,
        private messenger: Messenger,
        private authorizationService: AuthorizationService,
        private placebuzzSearchService: PlacebuzzSearchService,
        private trackingService: TrackingService,
        private platformService: PlatformHelpersService,
        private enquiriesService: EnquiriesService,
        private propertyDetailsService: PropertyDetailsService,
        private router: Router,
        private historyService: HistoryService,
        private location: Location
    ) {

        this.messenger.Subscribe(this.meStateChangedSubscriber);
    }
    ngOnDestroy(): void {
        this.messenger.Unsubscribe(this.meStateChangedSubscriber);
    }
  ngOnInit(): void {
    
    this.subscriptions.push(
        combineLatest([
          this.activatedRoute.paramMap,
          this.propertyDetailsService.getPropertyDetailsObservable()
        ])
        .subscribe(([params, details]) => {
          this.propertyDetails = details;
            this.isForSale = this.propertyDetails.listingTypeId === 2;
          this.mode = this.activatedRoute.snapshot.queryParams["mode"]||'contact_agent';
          this.photoUrl = this.propertyDetails.photos[0].replace("/photo/", "/c480x365/photo/");

          this.requestDetailsList = this.isForSale ? [...requestDetailsForSale] : [...requestDetailsForRent];

          for (var i = 0; i < this.requestDetailsList.length; i++) {
              this.requestDetailsSelections.push(false);
          }

          if (this.authorizationService.isLoggedIn && this.applicationStateService.me){
            this.name = this.applicationStateService.me.fullName;
            this.email = this.applicationStateService.me.email;
            this.telephone = this.applicationStateService.me.phoneNumber;
            this.postcode = this.applicationStateService.me.postcode;
          }


        })
      );
  }


  OnClickedBack() {
    if (this.historyService.canGoBack()) {
        this.location.back();
    }
    else {
        this.router.navigateByUrl(this.propertyDetails.detailsUrl);
    }
}

  private GetSimilarListings(listingId: string): Promise<void> {
    return new Promise(resolve => {
        if (this.platformService.IsBrowserPlatform) {
            var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.SimilarListingsQuery);
            queryBuilder.BuildMultiPartQuery([
                CreatePropertySimilarListingsQueryPart(listingId)
            ]);

            this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.SimilarListingsQuery, queryBuilder.query, queryBuilder.variables, () => this.GetSimilarListings(listingId), this, false, null, true).then(response => {
                var result = {};
                if (this.gqlService.ProcessResponse(result, response, true)) {
                    this.propertyDetails.similarListings = (<any>result).propertySimilarListings;
                }
                resolve();
            })
        }
        else {
            resolve();
        }
    });
}

  OnSimilarListingsObservedAction = new ActionAndContext<void>(() => {
    if (this.platformService.IsBrowserPlatform) {
        this.GetSimilarListings(this.propertyDetails.listingId.toString()).then(() => {
            for (var i = 0; i < this.propertyDetails.similarListings.length; i++) {
                this.propertyDetails.similarListings[i].eCommerceTracking.position = i + 1;
                this.propertyDetails.similarListings[i].eCommerceTracking.clientListForImpressions = this.trackingService.GetCurrentPageName() + SIMILAR_PROPERTIES_SUFFIX;
                this.propertyDetails.similarListings[i].eCommerceTracking.clientListForActions = this.trackingService.GetCurrentPageName() + SIMILAR_PROPERTIES_SUFFIX;
            }
            this.trackingService.TrackECommerceImpressions(this.propertyDetails.similarListings.map(l => l.eCommerceTracking));
        });
    }
}, this, null);


    OnClickedSendMessage() {
        this.ExecuteContactAgentMutation();
    }

    OnClickedContinueRequestDetails() {
        this.mode = CONTACT_AGENT_MODES.Request_Details_Step2;
    }

    OnClickedLogin() {
        var loginMessageData: ShowLoginMessageData = {
            showOverlay: true,
            isRegistration: false,
            prefillEmail: this.email
        };
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOGIN,
            messageData: loginMessageData
        });
    }

    OnClickedSendMagicLink() {
        var loginMessageData: ShowLoginMessageData = {
            showOverlay: true,
            isRegistration: false,
            autoSendMagicLinkEmail: this.email
        };
        this.messenger.Send({
            messageType: MESSAGE_TYPES.SHOW_LOGIN,
            messageData: loginMessageData
        });
    }

    SetCheckBoxOnClick(propName: string) {
      this[propName] = !this[propName];
    }

    private meStateChangedSubscriber: ISubscriber = {
      messageType: MESSAGE_TYPES.ME_STATE_CHANGED,
      On: () => {
        if (this.applicationStateService.me) {
          this.name = this.applicationStateService.me.fullName;
          this.email = this.applicationStateService.me.email;
          this.telephone = this.applicationStateService.me.phoneNumber;
          this.postcode = this.applicationStateService.me.postcode;
        }
      }
  }

    private ExecuteContactAgentMutation() {
        var contactAgentInput: ContactAgentInput = {
            campaignId: this.propertyDetails.campaignId,
            listingId: +this.propertyDetails.listingId,
            aboutMeId: null,
            email: this.email,
            employmentStatusId: null,
            financialDetailsId: null,
            message: this.message,
            name: this.name,
            postcode: this.propertyDetails.postcode,
            propertyViewingDate: null,
            propertyViewingTime: null,
            purchaseTypeId: null,
            telephone: this.telephone,
            listingTypeId: this.propertyDetails.listingTypeId,
            requestDetails: null,
            hasShareMyDetailsWithPartnerRequest: this.propertyDetails.hasShareMyDetailsWithPartner,
            mortgageQuoteInput: null
        }

        
        if (this.mode === CONTACT_AGENT_MODES.Request_Details_Step2) {
            contactAgentInput.requestDetails = new Array<string>();
            for (var i = 0; i < this.requestDetailsSelections.length; i++) {
                if (this.requestDetailsSelections[i] === true) {
                    contactAgentInput.requestDetails.push(this.requestDetailsList[i].key);
                }
            }
        }


        this.gqlService.ExecuteMutation(ContactAgentMutation, contactAgentInput, this.ExecuteContactAgentMutation, this).then(async response => {
            var result = {};
            if (this.gqlService.ProcessResponse(result, response)) {
                var mutationResult = (<any>result).contactAgent as ContactAgentMutationResult;
                this.validationErrors = mutationResult.errors;
                if (this.validationErrors.length === 0 && mutationResult.isValid === true) {
                    this.completed = true;
                    
                    this.trackEnquiry(mutationResult.price);

                    this.platformService.ScrollToTop();

                    this.ProcessEnquiryUser(mutationResult.isExistingUser, mutationResult.existingUserEmailOrTelephone, mutationResult.existingUserMessage, mutationResult.token);

                }
                else {
                    this.trackingService.TrackAction("Click", "Button", "Error (Validation): Send Message-" + this.mode);
                }
            }
            else {
                this.trackingService.TrackAction("Click", "Button", "Error (GraphQL): Send Message-" + this.mode);
            }
        })
    }


    private trackEnquiry(price:number){

        var eCommerceTrackingCopy = CopyObject(this.propertyDetails.eCommerceTracking);
            eCommerceTrackingCopy.price = price;
            this.trackingService.TrackConversion(eCommerceTrackingCopy, false, this.isForSale, null, "ecPurchase");

            if (this.mode != CONTACT_AGENT_MODES.Mortgage_Quote && this.mode != CONTACT_AGENT_MODES.Mortgage_Quote) {
                let enquiryItem = new EnquiryItem();
                enquiryItem.address = this.propertyDetails.address;
                enquiryItem.agentAddress = this.propertyDetails.agentAddress;
                enquiryItem.agentName = this.propertyDetails.agentName;
                enquiryItem.agentPhoneNumber = this.propertyDetails.agentPhoneNumber;
                enquiryItem.detailsUrl = this.propertyDetails.detailsUrl;
                enquiryItem.placeId = this.propertyDetails.listingId;
                enquiryItem.postcode = this.propertyDetails.postcode;
                enquiryItem.price = this.propertyDetails.price;
                enquiryItem.priceModifier = this.propertyDetails.priceModifier;
                enquiryItem.title = this.propertyDetails.title;
                enquiryItem.photos = this.propertyDetails.photos;
                enquiryItem.enquiryMessage = this.message;
                enquiryItem.enquiryId = 0;

                switch (this.mode) {
                    case CONTACT_AGENT_MODES.CONTACT:
                        enquiryItem.enquirySubType = { id: 3, name: "", lookUpOrder: 0 }
                        break;
                    case CONTACT_AGENT_MODES.Request_Details_Step1:
                    case CONTACT_AGENT_MODES.Request_Details_Step2:
                        enquiryItem.enquirySubType = { id: 2, name: "", lookUpOrder: 0 }
                        break;
                    case CONTACT_AGENT_MODES.Arrange_Viewing_Step1:
                    case CONTACT_AGENT_MODES.Arrange_Viewing_Step2:
                        enquiryItem.enquirySubType = { id: 1, name: "", lookUpOrder: 0 }
                        break;
                }

                this.enquiriesService.SavePendingEnquiry(enquiryItem);
            }

    }

    private ProcessEnquiryUser(isExistingUser: boolean, existingEmailOrPhone: string, existingUserMessage: string, token: string) {
        if (isExistingUser && !this.authorizationService.isLoggedIn) {
            this.existingUserEmailOrPhone = existingEmailOrPhone;
            this.existingUserMessage = existingUserMessage;
        }
        else if (token) {
            this.trackingService.TrackSignup();
            this.authorizationService.LoginUserWithToken(token).then(result => {
                if (result.authorizationProperties.authorizationCodeAccessToken) {
                    this.placebuzzSearchService.SaveLatestSearchToServer().then(() => {
                        setTimeout(() => {
                            this.messenger.Send({
                                messageType: MESSAGE_TYPES.SHOW_IN_APP_TOAST,
                                messageData: new ShowInAppToastMessageData("You are now logged in")
                            });
                        }, 500);
                    });

                }
            });
        }
    }

    

  
}
