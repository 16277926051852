<div class="property-listing {{this.isSponsored?'listing-sponsored-link':''}}" *ngIf="listing" itemscope itemtype="http://schema.org/Residence">
    <div style="display:none" itemprop="geo" itemscope itemtype="http://schema.org/GeoCoordinates">
        <meta itemprop="latitude" content="{{listing.latitude}}" />
        <meta itemprop="longitude" content="{{listing.longitude}}" />
    </div>
    <div style="display:none" itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <meta itemprop="streetAddress" content="{{listing.address}}" />
        <meta itemprop="addressCountry" content="{{listing.countryCode}}" />
    </div>

    <a class="property-listing-list  {{this.isSponsored?'sponsored':''}}"
        [ngClass]="{'property-listing-grid':mode==='grid','featured':listing.listingAdType === 'Featured' || listing.listingAdType === 'Premium'}"
        [href]="detailsUrl|safeUrl"
        (click)="OnClicked()"
        nav-anchor action-tracking action-tracking-values="Link,Click,Place Details"
        [server-side]="isSponsored" [new-tab]="isSponsored" [rel]="isSponsored?'sponsored':'follow'">
        <span *ngIf="isSponsored" class="sponsored-listing-header">Sponsored Advertisement</span>

        <section class="main-content">

            <span *ngIf="listing.listingAdType === 'Featured' || listing.listingAdType === 'Premium'" class="premium-listing">{{listing.listingAdType}} Listing</span>

            <section class="pic-container">
                <property-listing-image [src]="mainPhotoUrl" [alt]="listing.photosAlt" intersection-observer [observeFirstChildElement]="true" observeMargin="400px" [onSetupObserverCallback]="OnSetupObserverAction" [onObservedCallback]="OnObservedAction" ></property-listing-image>
                
                <div *ngIf="!isSponsored" class="camera"><span>{{listing.photosTotal}}</span></div>
                <div *ngIf="listing.tag && !isSponsored" class="on-listing-tag" [ngStyle]="{'background-color':listing.tagBackgroundColor}"><label>{{listing.tag}}</label></div>
                <div *ngIf="enquiryCaption" class="enquired-tag"><span class="{{enquiryIconClass}}"></span>{{enquiryCaption}}</div>
            </section>

            <section class="main-info">
                <section class="listing-price-time">
                    <div class="price">
                        <span class="main-price">{{listing.price}}</span>
                        <span class="price-modifier">{{listing.priceModifier}}</span>
                        <span *ngIf="listing.weeklyPrice && (listing.weeklyPrice.length > 0)" class="main-price">&nbsp;&#124;&nbsp;{{listing.weeklyPrice}}</span>
                        <span *ngIf="listing.weeklyPrice && (listing.weeklyPrice.length > 0)" class="price-modifier">pw</span>
                    </div>
                    <div class="time-elapsed">
                        <span>{{elapsedTimeDisplay}}</span>
                    </div>
                </section>

                <section *ngIf="!isSponsored" class="listing-features">
                    <property-features compact="true" [features]="listing.features"></property-features>
                </section>

                <section class="listing-subtitle">
                    <span itemprop="name" title="{{listing.subTitle}}">{{listing.subTitle}}</span>
                </section>

                <section class="listing-title">
                    <span>{{listing.title}}</span>
                </section>

                <section class="listing-description">
                    <span>{{listing.description}}{{listing.description?'...':''}}</span>
                </section>


                <section *ngIf="listing.recommendationDistance" class="listing-distance">
                    <span class="location-icon"></span><span>{{listing.recommendationDistance}}</span>
                </section>


                <section *ngIf="!isSponsored" class="listing-marketing-info">
                  <div *ngIf="listing.listerLogoUrl" class="listing-agent-logo">
                      <div [ngStyle]="{'background-image': 'url('+listing.listerLogoUrl+')'}"></div>
                  </div>
                  <div class="marketing-info">
                    <span>{{listing.marketingInfo}}</span>
                </div>
              </section>

              <section *ngIf="isSponsored" class="listing-marketing-info">
                  <div>
                    <div class="view-on">
                      <pb-icon name="externallink"/><span>View on advertiser site</span>
                    </div>
                    <span class="sponsored-text">Sponsored Ad</span>
                  </div>
              </section>

                <favourite-switch *ngIf="!isSponsored" [isSelected]="isFavourite===true" (requestSelectedChange)="IsFavouriteClicked($event)" [placeId]="listing.placeId"></favourite-switch>
            </section>

            <section *ngIf="mode!='grid' && (listing.listingAdType === 'Premium' || listing.listingAdType === 'Featured')" class="premium-photos">
                <ul>
                    <li *ngFor="let photo of premiumPhotos">
                        <property-listing-image [src]="photo" height="68" width="100" alt="Small Photo" intersection-observer [observeFirstChildElement]="true" observeMargin="400px" [onSetupObserverCallback]="OnSetupObserverAction" [onObservedCallback]="OnObservedAction" ></property-listing-image>
                    </li>
                </ul>
            </section>
            
        </section>
    </a>
</div>


