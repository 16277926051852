import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, Virtual, Lazy } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { SwiperComponentBase } from 'src/app/components/component.base.classes/swiper.component.base';
import { SimilarProperty } from 'src/app/services/search.module/model/similarProperty';
import { NavAnchorDirective } from 'src/app/directives/nav-anchor.directive';
import { SafeUrlPipe } from 'src/app/pipes/safe.url.pipe';

SwiperCore.use([Navigation, Pagination, Scrollbar, Virtual, Lazy]);

@Component({
    selector: 'property-carousel',
    templateUrl: './property-carousel.component.html',
    styleUrls: ['./property-carousel.component.scss'],
    imports: [CommonModule, RouterModule, SwiperModule, NavAnchorDirective, SafeUrlPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyCarouselComponent extends SwiperComponentBase {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(private cdr: ChangeDetectorRef){
    super();
    this.reduceSlidesOnMobile = true;
  }
  
  public activeSlide = 1;
  totalSlides = 0;
  _images: string[] = [];
  _sliderImages: string[] = [];

  counterText:string;

  
  
  @Input() hasCounter: boolean = false;
  @Input() mode:string = "image";
  @Input() fullScreenUrl: string;
  @Input() isExternalLink: boolean = false;
  @Input() isMobileApp: boolean = false;

  @Input() properties: Array<SimilarProperty>;

  onSlideChange() {
    this.activeSlide = this.swiper?.swiperRef.activeIndex + 1;
    this.counterText = `${this.activeSlide} / ${this.totalSlides}`;
    this.cdr.detectChanges();
  }

  

}
