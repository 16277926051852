import { Component, OnDestroy, OnInit, NgModule, ViewEncapsulation } from "@angular/core";
import { PAGE_TRACKING_NAMES } from "src/app/infrastructure/tracking.constants";
import { SearchAcrossComponent } from "../search-across/search-across.component";
import { AppMainPageWrapperNoBorder } from "src/app/components/layout/app-main-page-wrapper-no-border/app-main-page-wrapper-no-border.component";
import { MetaTag } from "src/app/services/head.module/model";

import { HeadService } from "src/app/services/head.module/head.service";

import { TrackingService } from "src/app/services/tracking.module/tracking.service";
import { StatusCodeService } from "src/app/services/status.code.module/status.code.service";
import { PbImageComponent } from "src/app/controls/pb-image/pb-image.component";



@Component({
    selector: 'four-o-four',
    templateUrl: './four-o-four.container.html',
    styleUrls: ['./four-o-four.container.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [AppMainPageWrapperNoBorder, SearchAcrossComponent]
})
export class FourOFourContainer  implements OnInit, OnDestroy {
    private metaTags = new Array<MetaTag>();
    
    constructor(
        private headService: HeadService,
        
        private statusCodeService: StatusCodeService,
        private trackingService: TrackingService) {


        this.metaTags.push(
            { name: "robots", content: "noindex,follow" }
        );

        this.trackingService.SetCurrentPageName(PAGE_TRACKING_NAMES.Four0Four);
        this.headService.SetHead("Search for a property for sale or to rent | Placebuzz", this.metaTags, null);
        this.statusCodeService.SetStatusCode(404);
        
    }

  
    CreateMetaTags(): void {
        
        this.headService.SetHead(
            "Placebuzz - Not Found",
            this.metaTags,
        null);
    }
    SetPageTracking(): void {

    }

    ngOnInit(): void {

    }
    ngOnDestroy(): void {

    }
}
